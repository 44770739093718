.bookmark {
  &-wrapper {
    padding: 1rem;
    border-bottom: 1px solid #d5d5db;
    display: grid;
    grid-template-columns: 1fr 10%;
    .type {
      font-size: 12px;
      text-transform: uppercase;
      font-family: var(--elliot-bold);
      line-height: 20px;
      color: #000;
    }
    .checkbox-wrapper {
      height: 20px;
      width: 20px;
      margin-right: 10px;

      img {
        filter: none !important;
      }
    }

    .bookmark-title-type {
      display: flex;
      flex-direction: row;
      grid-column: 1/-1;
    }
  }
  &-header {
    grid-column: 1/-1;
    &-img {
      img {
        width: 100%;
        filter: none !important;
      }
    }
  }
  &-infos {
    padding: 1rem 0;
    .organization {
      color: #afafaf;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 1px;
      line-height: 18px;
      text-transform: uppercase;
    }
    .name {
      color: #000000;
      font-size: 18px;
      letter-spacing: 0;
      line-height: 22px;
    }
  }
  &-cta {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      filter: var(--filter-grey) !important;
      width: 50%;
      height: 70%;
    }
  }
  &-warning {
    color: red;
    font-size: 10px;
    letter-spacing: 1.25px;
  }
}
