.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
  pointer-events: none;
}
.gu-hide {
  display: none !important;
}
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
.gu-transit {
  display: none; /* hack */
}
.drag {
  height: 840px;
  margin-bottom: 48px;
  overflow-y: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #F7F8F9;
    border-radius: 4px;
    border: 1px solid #3C4E65;
  }

  &::-webkit-scrollbar-thumb {
    background: #3C4E65;
    border-radius: 4px;
  }

  &.scroll-down {
    min-width: 100%;
    max-width: 100%;
    padding: 0;
    margin: 0;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-bottom: 54px;
  }

  &.ignore-events{
    pointer-events: none;
  }
}
.arrow-up {
  width: 30px;
  margin-right: 10px;
  position: fixed;
  bottom: 6px;
  right: -5px;
  height: 65px;
  z-index: 999;
}
