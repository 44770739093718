@import "styles/_variables";

.tabs-container {
  width: calc(100% + 8px);
  margin-left: -4px;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  height: 27px;
  overflow-x: scroll;

  -ms-overflow-style: none; /* HIDE SCROLLBAR */
  scrollbar-width: none; /* HIDE SCROLLBAR */

  &::-webkit-scrollbar {
    display: none; /* HIDE SCROLLBAR */
  }

  & .tab {
    font-family: var(--elliot-regular);
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 14px;
    color: #050505;
    margin-right: 20px;
    flex-shrink: 0;
    text-decoration: none;
    cursor: pointer;
  }
}

.tab.selected {
  font-family: var(--elliot-bold);
  border-bottom: 4px solid #0c233f;
}

.tab-content {
  display: none;
  &.visible-tab {
    display: block;
  }
}

/* Desktop Min Width 900 */

@media (min-width: $breakpoint-tablet) {
  /* TABS */
  .tabs-container {
    width: 100%;
    margin: 0;
    height: 28px;

    & .tab {
      font-size: 14px;
      letter-spacing: 1.17px;
      margin-right: 39px;
    }
  }
}

.contributor {
  &-banner {
    grid-column: 1/-1;
    grid-row: 1/2;
    background-color: #0d243f;
    height: 48px;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1.17px;
    line-height: 10px;
    padding: 1rem 6rem;
    margin-left: -4rem;
    margin-right: -4rem;
  }
  &-header {
    display: grid;
    grid-template-columns: 25rem 1fr 20rem;
    grid-template-rows: 3rem 7rem 4rem;
    align-items: center;
    padding-top: 10rem;
    margin-bottom: 3rem;

    .coe-select {
      padding: 1.5rem 2rem;
      color: #000;
      grid-row: 2/3;
      grid-column: 1/2;

      .custom-select-options {
        z-index: 50;
        border: 0.1px solid gray;
      }
    }
    .organizational-unit {
      display: flex;
      align-items: center;
      grid-row: 3/4;
      grid-column: 1/2;

      .image {
        flex-basis: 25%;
        img {
          width: 100%;
        }
        margin-right: 2rem;
      }

      .name {
        color: #353535;
        font-size: 24px;
        letter-spacing: 0;
        line-height: 32px;
      }
    }
    .title {
      color: #353535;
      font-size: 56px;
      letter-spacing: 0;
      line-height: 64px;
      grid-row: 2/4;
      grid-column: 2/3;

      span {
        color: #353535;
        font-size: 40px;
      }
    }
    .action {
      grid-row: 2/3;
      grid-column: 3/4;
      .add-item {
        height: 40px;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 1px;
        line-height: 21px;
        text-align: center;
        background-color: #3c4e65;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50px;
        cursor: pointer;
        color: #fff;
        &.disabled {
          pointer-events: none;
          opacity: 0.4;
        }
      }
    }
    .search {
      grid-row: 3/4;
      grid-column: 3/4;
    }
    .tabs-container {
      grid-row: 3/4;
      grid-column: 2/3;
    }
  }
  &-description {
    margin-bottom: 1rem;
    p {
      color: #000;
      margin-bottom: 4px;
      font-size: 18px;
    }
  }
  &-buttons_container {
    display: flex;
    justify-content: end;
    margin-bottom: 1rem;
    .action {
      width: 23%;
      .add-item {
        height: 40px;
        padding: 0 10px;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 1px;
        line-height: 21px;
        text-align: center;
        background-color: #3c4e65;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50px;
        cursor: pointer;
        color: #fff;
        &.disabled {
          pointer-events: none;
          opacity: 0.4;
        }
      }

      .button-container{
        display: flex;
        text-transform: uppercase;
        justify-content: space-between;
        .action-button {
          height: 40px;
          width: 170px;
          font-size: 16px;
          font-weight: bold;
          letter-spacing: 1.14px;
          line-height: 21px;
          text-align: center;
          background-color: #3c4e65;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50px;
          cursor: pointer;
          color: #fff;
          margin-left: 20px;
          &.disabled {
            pointer-events: none;
            opacity: 0.4;
          }
        }
      }
    }
  }
}

