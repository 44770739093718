@import "styles/_variables";

.accordion-industry {
  background-color: #f7f8f9;
  margin-top: 25px;
  min-height: 20px;
  margin-left: auto;
  margin-right: auto;


  .accordion-header {
    position: relative;
    color: black;
    height: 100%;
    &.open {
      border-bottom: solid 0.3rem var(--main-bg);

      @media (min-width: $breakpoint-tablet) {
        border-bottom: solid 0.5rem var(--main-bg);
      }
    }

    .plus-icon {
      margin-left: auto;
      margin-right: 2rem;
      height: 12px;
      width: 12px;
      filter: invert(16%) sepia(97%) saturate(4586%) hue-rotate(333deg) brightness(85%) contrast(112%);

      @media (min-width: $breakpoint-tablet) {
        height: 18px;
        width: 18px;
      }
    }
  }
  .accordion-content-container {
    background-color: white;
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.3s;
    .accordion-content {
      padding-bottom: 0;
    }
    .accordion-content > * {
      color: #909090;
      line-height: 24px;
      b {
        color: #909090;
        font-family: var(--elliot-bold);
      }
    }
  }

  .industry-card {
    display: flex;
    cursor: pointer;
    padding: 0.5rem;
    height: 100%;
    align-items: center;

    @media (min-width: $breakpoint-tablet) {
      margin: 0;
    }

    .logo-img {
      position: relative;
      img {
        position: unset;
        width: 46px;
        border-radius: 50%;
        height: 46px;
        transform: none;
        @media (min-width: $breakpoint-tablet) {
          width: 80px;
          height: 80px;
        }
      }
    }
    .type {
      color: #0c2340;
      font-size: 10px;
      font-weight: bold;
      letter-spacing: 0.83px;
      line-height: 10px;
      text-transform: uppercase;
      grid-column: 2/-1;

      @media (min-width: $breakpoint-tablet) {
        font-weight: bold;
        letter-spacing: 1px;
        line-height: 20px;
      }
    }
    .title {
      color: #000000;
      font-size: 18px;
      letter-spacing: 0;
      line-height: 22px;
      padding: 0 1rem;
      @media (min-width: $breakpoint-tablet) {
        font-size: 24px;
        letter-spacing: 0;
        line-height: 32px;
      }
    }

  }

  @media (min-width: $breakpoint-tablet) {
      .accordion-content-container {
        .accordion-content {
          margin-top: 2rem;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-column-gap: 1rem;
          column-gap: 1rem;
          grid-row-gap: 2rem;
          row-gap: 2rem;
          justify-content: space-between;
          padding-bottom: 41px;
          //background-color: #0e4576;

          @media (min-width: $breakpoint-tablet) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            column-gap: 1rem;
            row-gap: 2rem;
          }
          @media (min-width: 1360px) {
            grid-template-columns: repeat(3, 1fr);
          }
        }
        .accordion-content > * {
          font-size: 18px;
          line-height: 28px;
        }
      }
    }
}

