/* style.scss */
.news-list-container {
  max-width: 750px;
  margin: 20px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 40px;
}

.news-summary a {
  color: #000; /* This is a common blue color for links, but you can choose any color that fits your theme */
  text-decoration: underline; /* Adds underline to make it clear it's a hyperlink */
  font-weight: bold; /* Optional: makes the link text bold for better visibility */
  &:hover {
    color: #000; /* Darker shade of blue for hover effect */
    text-decoration: none; /* Optional: removes underline on hover for a clean look */
  }
}

.news-list-title {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

.news-items {
  list-style: none;
  padding: 0;
}

.news-item {
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #eaeaea;
}

.news-date {
  font-size: 14px;
  color: #666;
  margin-bottom: 5px;
}

.news-title {
  font-size: 18px;
  color: #000;
  margin-bottom: 5px;
}

.news-summary {
  font-size: 16px;
  color: #555;
}
