@import "styles/_variables";

.related-cards {
  margin-bottom: 17px;
  &:last-child {
    margin-bottom: 37px;
  }
  & .title {
    font-family: var(--elliot-bold);
    color: #000000;
    width: 100%;
    margin-bottom: 20px;
    letter-spacing: 1px;
    line-height: 18px;
    font-size: 12px;
  }
  & .cards-list {
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    overflow-x: scroll;
    margin-left: -2rem;
    padding-left: 2rem;
    min-height: 12rem;
    > * {
      min-width: 100%;
      margin-right: 2rem;
    }
    & .offering-div {
      .offering-card {
        height: 95%;
      }
    }
    & .qual-div {
      .qual-card {
        max-height: 95%;
      }
    }
  }
  & .cards-divider {
    width: calc(100% + 4rem);
    margin-left: -2rem;
    opacity: 0.28;
    height: 1px;
    background-color: #979797;
  }
  & .scroll-button {
    display: none;
  }

  @media (min-width: $breakpoint-tablet) {
    position: relative;
    margin-bottom: 40px;
    & .title {
      margin-bottom: 32px;
      letter-spacing: 1.33px;
      line-height: 18px;
      font-size: 16px;
      z-index: 150;
    }
    & .cards-list {
      justify-content: flex-start;
      overflow-x: hidden;
      width: 100%;
      margin-right: 0;
      margin-left: 0;
      padding-left: 0;
      > * {
        box-sizing: border-box;
        min-width: calc(28% - 21px);
        max-width: calc(25% - 21px);
        margin-right: 28px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
    & .cards-divider {
      width: calc(100% + 8rem);
      margin-left: -4rem;
      opacity: 0.28;
      height: 1px;
      background-color: #979797;
    }
    & .scroll-button {
      display: none;
      position: absolute;
      top: 16px;
      bottom: 0;
      width: 10%;
      align-items: center;
      z-index: 100;
      cursor: pointer;
      &.active {
        display: flex;
      }
      & img {
        height: 20px;
      }
      &.left-scroll {
        background: linear-gradient(90deg, white 0%, #fff0 100%);
        left: -1px;
      }
      &.right-scroll {
        & img {
          transform: scaleX(-1);
        }
        justify-content: flex-end;
        background: linear-gradient(90deg, #fff0 0%, white 100%);
        right: -1px;
      }
    }
  }
}
