@import "styles/_variables";

.coll-rules-container {
  .features-container {
    padding: 48px 32px;
    width: calc(100% + 64px);
    margin-left: -32px;
    background-color: #f7f8f9;
    > * {
      margin-bottom: 40px;
      color: #353535;
    }
    .title {
      display: flex;
      align-items: center;
      font-family: var(--elliot-bold);
      letter-spacing: 0;
      line-height: 24px;
      font-size: 20px;
      margin-bottom: 16px;
      color: #353535;
      img {
        width: 40px;
        height: 40px;
        margin-right: 16px;
      }
    }
  }
  .title-container:not(.blue-image) {
    padding-top: 56px;
    background-color: #fff;
    .title {
      color: #0d243f;
    }
  }
  .accordions-container {
    margin-bottom: 180px;
  }
}

@media (min-width: $breakpoint-tablet) {
  .coll-rules-container {
    .features-container {
      padding: 83px 94px;
      width: calc(100% + 128px);
      margin-left: -64px;
      display: flex;
      justify-content: space-between;
      > * {
        width: 30%;
        margin-bottom: 0;
        line-height: 28px;
        font-size: 18px;
      }
      .title {
        line-height: 32px;
        font-size: 24px;
        margin-bottom: 24px;
        img {
          margin-right: 24px;
        }
      }
    }
    .title-container:not(.blue-image) {
      padding-top: 94px;
      .title {
        color: #353535;
      }
      .subtitle {
        max-width: 37%;
      }
    }
    .accordions-container {
      max-width: 60%;
      margin-bottom: 180px;
    }
  }
}
