@font-face {
  font-family: "FS Elliot Pro";
  src: url("./fonts/FSElliotPro.otf");
}
@font-face {
  font-family: "FS Elliot Pro Bold";
  src: url("./fonts/FSElliotProBold.otf");
}
@font-face {
  font-family: "FS Elliot Pro Heavy";
  src: url("./fonts/FSElliotProHeavy.otf");
}
@font-face {
  font-family: "FS Elliot Pro Light";
  src: url("./fonts/FSElliotProLight.otf");
}
@font-face {
  font-family: "FS Elliot Pro Thin";
  src: url("./fonts/FSElliotProThin.otf");
}
