@import "styles/_variables";

.profile-container {
  color: var(--white);
  height: 10rem;
  padding: 2rem;
  background-color: var(--black);

  @media (min-width: $breakpoint-tablet) {
    background: transparent;
    padding: 0;
    height: initial;

    position: relative;
  }

  .title {
    font-family: var(--elliot-bold);
    text-transform: uppercase;
    font-size: 0.7rem;
    padding-bottom: 0.8rem;
    @media (min-width: $breakpoint-tablet) {
      display: none;
    }
  }

  .card {
    width: 100%;
    display: grid;
    grid-template-columns: 5rem 1fr 1rem;
    gap: 0.6rem;
    @media (min-width: $breakpoint-tablet) {
      grid-template-columns: 3rem 1fr;
      grid-template-rows: 3rem;
      gap: 1rem;
    }

    .info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      @media (min-width: $breakpoint-tablet) {
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
      }

      .name {
        font-family: var(--elliot-bold);
        padding-right: 1rem;
      }
      .area-title,
      .area {
        font-size: 0.9rem;
        color: var(--gray);
        @media (min-width: $breakpoint-tablet) {
          display: none;
        }
      }
    }

    .avatar {
      grid-row: 1/-1;
      grid-column: 1/2;
    }
    .cta {
      grid-row: 1/-1;
      grid-column: 3/4;
      svg {
        rect {
          fill: var(--white) !important;
        }
      }
      @media (min-width: $breakpoint-tablet) {
        display: none;
      }
    }
  }

  .open-down {
    width: 15px;
    height: 15px;
    svg {
      fill: var(--white);
    }
    display: none;
    @media (min-width: $breakpoint-tablet) {
      display: block;
    }
  }
}
