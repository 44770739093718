.inspirations {
    display: flex;
    align-items: stretch;
    flex-direction: column;
    justify-content: flex-start;

    & a {
        padding: 12px 0;
        font-family: var(--elliot-bold);
        background: white;
        color: #0c2340;
        font-size: 12px;
        text-align: center;
        letter-spacing: 1px;
        border-radius: 4px;
        text-decoration: none;
        line-height: 18px;
        margin-bottom: 24px;
    }

    & a:last-child {
        margin-bottom: 0;
    }
}