.user-detail {
  .avatar-container {
    height: 100px;
    width: 100px;
    margin-bottom: 30px;
  }
  .enabled {
    background-color: white!important;
    border: solid 0.5px #8C8E90!important;
  }
  .sync {
    background-color: transparent!important;
    font-weight: bold;
    letter-spacing: 1.5px;
    padding-left: 0!important;

    &-true {
      color: #3289ED!important;
    }

    &-false {
      color: #FF0000!important;
    }
  }

  /* The switch - the box around the slider */
  .switch {
    position: relative;
    display: inline-block!important;
    width: 64px!important;
    height: 34px;
  }

  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }

  input:checked + .slider {
    background-color: #0D243F;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #0D243F;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }

  .switch-label {
    color: black;
    margin-left: 20px;
    top: 12px;
    position: relative;
  }
}