@import "styles/_variables";

.table-container {
  background-color: #ffffff;
  width: 100%;
  //overflow: scroll;
  padding-bottom: 100px;

  .pre-header-data {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 56px;
    .massive-action-container {
      display: flex;
      flex-direction: row;

      .massive-action {
        font-family: var(--elliot-bold);
        color: #3C4E65;
        font-size: 14px;
        text-transform: uppercase;
        text-decoration: underline;
        opacity: 0.5;
        margin-right: 15px;

        &-active {
          opacity: 1;
          cursor: pointer;
        }
      }
    }
  }

  .actions-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;

    .empty {
      width: 17px;
    }
  }

  .ReactTable {
    border: none;

    .rt-table {
      min-width: 700px;

      .-header {
        background-color: #0D243F;
        height: 48px;

        .rt-th {
          align-items: center;
          display: flex;
          justify-content: center;

          div {
            font-size: 14px;
            font-family: var(--elliot-bold);
          }
        }
      }

      .rt-tbody {
        border: 1px solid rgba(60, 60, 60, 0.2);

        .rt-tr-group {
          border-bottom: none;
          .-odd {
            background-color: rgba(13, 36, 63, 0.04);
          }
        }
      }

      .rt-td {
        width: auto;
        align-items: center;
        justify-content: center;
        display: flex;
        border-right: none;
        min-height: 64px;
        overflow: visible;
        white-space: normal;

        .status-container {
          padding: 0 5px;
          height: 24px;
          text-align: center;
          line-height: 24px;
          border-radius: 12px;
          font-size: 12px;
          font-family: var(--elliot-bold);
        }

        .active {
          background-color: rgba(42, 182, 115, 0.09);
          color: #2AB673;
        }

        .disabled {
          color: #3C3C3C;
          background-color: rgba(60, 60, 60, 0.09);
        }

        .severity-container {
          display: flex;
          flex-direction: row;

          .status-container {
            margin: 0 5px;
            flex-wrap: wrap;
          }

          .error-LOW {
            background-color: rgba(42, 182, 115, 0.09);
            color: #2AB673;
          }

          .error-MEDIUM {
            color: #3C3C3C;
            background-color: rgba(60, 60, 60, 0.09);
          }

          .error-HIGH {
            color: #FF0000;
            background-color: rgba(255, 0, 0, 0.09);
          }
        }

        .active-trees {
          color: #FF0000;
          font-family: var(--elliot-bold);
          font-size: 14px;
        }

        .synced {
          color: #3289ED !important;
        }

        .checkbox-wrapper {
          height: 20px;
          width: 20px;
          margin: 0 10px;

          img {
            filter: none !important;
          }
        }
      }

      .table-text-color {
        color: #353535;
        font-size: 12px;
        font-family: var(--elliot-regular);
        text-align: center;
        width: 100%;
        overflow-wrap: break-word;
      }

      .table-link {
        color: #00569c;
        font-size: 12px;
        font-family: var(--elliot-regular);
        text-align: center;
        width: 100%;
        text-decoration: underline;
      }

      .align-start {
        text-align: start;
        padding: 0 10px;
      }

      .user-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        width: 100%;

        span {
          margin-left: 10px;
          text-align: start;
        }
      }
    }

    .pagination {
      box-shadow: none;
      border-top: none;
      height: 48px;
      align-items: center;
      justify-content: center;
      display: flex;

      .page-number {
        color: #0D243F;
        padding: 0 10px;
      }

      .change-page {
        background-color: transparent;
        border: none;

        &:disabled {
          display: none;
        }
        .arrow {
          border: solid;
          border-width: 0 3px 3px 0;
          display: inline-block;
          padding: 3px;
          border-color: #0D243F;
          padding: 5px;
          margin: 3px;
        }

        .right {
          transform: rotate(-45deg);
          -webkit-transform: rotate(-45deg);
        }

        .left {
          transform: rotate(135deg);
          -webkit-transform: rotate(135deg);
        }
      }
    }
  }
}

.groups-management {
  .table-container {
    .rt-resizable-header-content {
      text-align: start;
      width: 100%;
      padding: 0 10px;
    }
  }
}
