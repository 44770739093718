@import "styles/_variables";

.detail-back-save {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  margin-left: -2rem;
  margin-right: -2rem;
  padding: 0 2rem;
  border-bottom: 1px solid #eee;
  @media (min-width: $breakpoint-tablet) {
    border-bottom: none;
  }
  .back-link {
    span {
      display: none;
      @media (min-width: $breakpoint-tablet) {
        display: initial;
      }
    }
  }
  .add-bookmark {
    cursor: pointer;
    margin-left: 10px;
  }

  .icons {
    display: inline-flex;
    margin-bottom: auto;
    margin-top: 5px;
    cursor: pointer;
    .edit-backsave {
      @media (max-width: $breakpoint-tablet) {
        display: none;
      }
      &__image {
        margin-top: 5px;
      }
    }
    .delete-backsave {
      @media (max-width: $breakpoint-tablet) {
        display: none;
      }
      &__image {
        margin-top: 5px;
        margin-left: 10px;
      }
    }
  }
}
