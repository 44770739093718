:root {
  //colors
  --white: #ffffff;
  --black: #000000;
  --menu-bg: #1d1d1d;
  --gray: #a6a6a6;
  --light-gray: #c4c4c4;
  --yellow: #ffd200;
  --green: #00c84f;
  --main-bg: #0c2340;
  //fonts
  --elliot-regular: "FS Elliot Pro";
  --elliot-bold: "FS Elliot Pro Bold";
  --elliot-heavy: "FS Elliot Pro Heavy";
  --elliot-light: "FS Elliot Pro Light";
  --elliot-thin: "FS Elliot Pro Thin";
  //filters
  --filter-white: invert(99%) sepia(100%) saturate(0%) hue-rotate(153deg)
    brightness(109%) contrast(100%);
  --filter-black: invert(0%) sepia(0%) saturate(0%) hue-rotate(223deg)
    brightness(97%) contrast(107%);
  --filter-red: invert(16%) sepia(97%) saturate(4586%) hue-rotate(333deg)
    brightness(85%) contrast(112%);
  --filter-grey: invert(47%) sepia(4%) saturate(64%) hue-rotate(187deg)
    brightness(96%) contrast(90%);
}
$breakpoint-mobile-mini: 322px;
$breakpoint-mobile: 912px;
$breakpoint-tablet: 992px;
$breakpoint-laptop: 1200px;
$breakpoint-middle-laptop: 1450px;
$breakpoint-large-laptop: 1600px;
