@import "styles/_variables";

.bipunit-card {
  border: 0.5px solid #979797;
  margin: 1rem 0;
  display: grid;
  grid-template-columns: 3rem 1fr 1rem;
  height: 15rem;
  cursor: pointer;
  padding: 1rem;

  @media (min-width: $breakpoint-tablet) {
    margin: 0;
    grid-template-columns: 6rem 1fr 1rem;
  }

  .logo-img {
    img {
      width: 46px;
      border-radius: 50%;
      height: 46px;
      @media (min-width: $breakpoint-tablet) {
        width: 80px;
        height: 80px;
      }
    }
  }
  .type {
    color: #0c2340;
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 0.83px;
    line-height: 10px;
    text-transform: uppercase;
    grid-column: 1/-1;

    @media (min-width: $breakpoint-tablet) {
      font-weight: bold;
      letter-spacing: 1px;
      line-height: 20px;
    }
  }
  .title {
    color: #000000;
    font-size: 24px;
    letter-spacing: 0;
    line-height: 22px;
    padding: 0 1rem;
    @media (min-width: $breakpoint-tablet) {
      font-size: 30px;
      letter-spacing: 0;
      line-height: 32px;
    }
  }
  .cta {
    img {
      filter: invert(16%) sepia(97%) saturate(4586%) hue-rotate(333deg)
        brightness(85%) contrast(112%);
      width: 100%;
    }

    svg {
      width: 100%;
      height: 100%;
      rect {
        fill: #e40046;
        stroke: #e40046;
      }
    }
  }
  .description {
    color: #000000;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
    background-color: #f7f8f9;
    grid-column: 1/-1;
    margin-left: -0.9rem;
    margin-bottom: -0.9rem;
    margin-right: -0.9rem;
    padding: 1rem;

    @media (min-width: $breakpoint-tablet) {
      font-size: 18px;
      letter-spacing: 0;
      line-height: 28px;
    }
  }
  .CKEditor-container {
    color: black !important;

    span {
      color: black !important;
    }

    p {
      color: black !important;
    }

    strong {
      color: black !important;
    }

    i {
      color: black !important;
    }

    u {
      color: black !important;
    }

    p::before {
      content: none !important;
    }

    li {
      color: black !important;
    }

    label {
      display: none !important;
    }

    .ck-editor__editable {
      padding-left: 20px !important;
    }

    .text-big {
      font-size: 1.25rem;
    }

    .text-tiny {
      font-size: 0.85rem;
    }

    .ck-fontsize-option {
      font-size: 1rem;
    }

    .ck.ck-editor__editable > .ck-placeholder::before {
      color: #d21714;
    }

    li::marker {
      padding-left: 10px;
      color: black;
      //font-size: 20px;
      margin-left: 20px;
    }
  }
}

@media (max-width: 982px) {
  .bipcard {
    margin: 1rem 0;
  }
  .bipunit-card {
    margin: 0 !important;
  }
}
