.cardcontact {
  &-wrapper {
    min-height: 6rem;
    padding: 1rem 0;
    display: grid;
    grid-template-columns: 5rem 1fr;
    align-items: start;

    border-bottom: 0.5px solid #bfbfc9;

    a {
      font-size: 14px;
      letter-spacing: 0;
      line-height: 20px;
      color: #0d243f;
    }

    div {
      font-size: 14px;
      letter-spacing: 0;
      line-height: 20px;
      color: #0d243f;
    }
    .infos {
      padding: 0 1rem;
      .name {
        font-family: var(--elliot-bold);
      }
      .email {
        margin-top: 1rem;
      }
    }
  }
}
