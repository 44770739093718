@import "styles/_variables";

.categories-container {
  width: 400px;
  margin-right: 62px;
  font-size: 18px;
  //line-height: 60px;
  text-transform: uppercase;
  margin-left: -4rem;

  .filter-number {
    background-color: #0c2340;
    border-radius: 50%;
    width: 1.8rem;
    height: 1.8rem;
    font-size: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    padding: 15px;
  }

  .uncategorized .label{
    color: #000;
  }

  & .icon-container{
    display: flex;
    position: absolute;
    right: 148px;
    width: 170px;
    justify-content: space-between;
  }
}

@media (max-width: $breakpoint-laptop) {
  .categories-container {
    font-size: 14px;
  }
}




.level {
  padding-left: 15px;
  background-color: #F7F8F9;
  border-radius: 0 24px 24px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 13px;
  margin-bottom: 10px;
  height: 60px;

  & .level-marker {
    display: flex;
    color: #DA291C;
    width: 30px;
    height: 35px;
    background-color: #fff;
    justify-content: center;
    align-items: center;
    font-size: 38px;
    margin-right: 20px;
    cursor: pointer;
  }


  & .label{
    margin-right: auto;
    color: #000;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  &-children {
    &-1{
      padding-left: 25px;
      margin-left: 25px;
      position: relative;
    }
    &-2{
      padding-left: 50px;
      margin-left: 50px;
      position: relative;
    }
    &-3{
      padding-left: 75px;
      margin-left: 75px;
      position: relative;
    }

  }

  &.active {
    background-color: #3C4E65;
    color: #fff;

    & .label{
      color: #fff;
    }

  }

  &.icon-container{
    display: flex;
    position: absolute;
    right: 177px;
    width: 170px;
    justify-content: space-between;
  }

  .disabled {
    opacity: 0.1 ;
  }

  .label-container{
    margin-right: auto;
    width: 100%;
    height: 60%;
    display: flex;
    align-items: center;
  }

  &-highlighted {
    background-color: #558ab8;
    .label{
      color: #fff;
      font-weight: bold;
    }
  }
}

