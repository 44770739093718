@import "styles/_variables";

.myracle-link {
  width: 100%;
  background-color: white;
  padding: 12px;
  text-align: center;
  border-radius: 4px;
  font-size: 12px;
  line-height: 18px;
  color: #0c2340;
  text-transform: uppercase;
  text-decoration: none;

  @media (min-width: $breakpoint-tablet) {
    font-size: 14px;
  }
}
