@import "styles/_variables";

.table-action-container {
  position: absolute;
  height: 18px;
  background-color: #ffffff;

  .open-action-button {
    width: 30px;
    height: 18px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: #DEDEDE;
    border-radius: 2px;

    &.active-button {
      background-color: #3C3C3C;
    }

    .action-point {
      height: 4px;
      width: 4px;
      border-radius: 2px;
      background-color: #3C3C3C;
      margin: 0 1px;

      &.active-point {
        background-color: #DEDEDE;
      }

    }
  }

  .visible {
    display: block !important;
  }

  .actions-block {
    position: absolute;
    width: 183px;
    display: none;
    right: 0;
    border: 0.5px solid #DEDEDE;
    z-index: 20;

    .action-label {
      line-height: 48px;
      color: #353535;
      font-size: 14px;
      background-color: #ffffff;
      padding: 0 10px;
      cursor: pointer;
    }

    .odd {
      background-color: #d7d8d9;
    }
  }
}
