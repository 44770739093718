@mixin background-image-blue {
  background: url("../images/shape\ background.svg") no-repeat #0d2540;
  background-size: contain;
  background-position-y: center;
  background-position-x: right;
}

@mixin background-image-gray {
  background: url("../images/shape\ background.svg") no-repeat #0d2540;
  background-size: contain;
  background-position-y: center;
  background-position-x: right;
}
