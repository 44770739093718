@import "styles/_variables";

.container-about {
  .title-container {
    min-height: 100vh;
  }

  .title {
    margin-bottom: 0;
  }
  .subtitle {
    max-width: 60%;
  }

  @media (min-width: $breakpoint-tablet) {
    .subtitle {
      max-width: 80%;

      .row {
        display: flex;
        flex-direction: row;
        top: -20px;
      }


      .half-section{
        max-width: 50%;

        &.right {
          padding-left: 100px;
          margin-right: -100px;
        }
      }
    }
  }

  .support {
    padding: 3rem 0;
    p {
      margin-bottom: 1.5rem;
    }
    a,
    a:hover,
    a:visited,
    a:focus {
      text-decoration: none;
    }
    .contacts {
      padding-top: 22px;
      .contact {
        padding: 0;
        font-size: 1.2rem;
        line-height: 2.14;
        margin-bottom: .5rem;
        a:hover {
          text-decoration: underline;
        }
      }

    }
    .contact-description {
      margin-bottom: 3.5rem;
    }
  }
}
