@import "styles/_variables";

.bookmarks {
  &-panel {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #f7f8f9;
    padding: 2rem 1rem;
    flex-direction: column;
    justify-content: space-between;
    z-index: 200;
    display: none;
    &.visible {
      display: flex;
    }

    @media (min-width: $breakpoint-tablet) {
      position: absolute;
      top: 3rem;
      left: -21rem;
      width: 22rem;
      height: 35rem;
      max-height: 80vh;
      background: none;
      background-color: var(--white);
      box-shadow: -1px 2px 7px -2px #4a4a4a;
      border-radius: 0.1rem;
      overflow: auto;
    }
  }
  &-content {
    overflow: auto;

    &-title {
      display: grid;
      grid-template-columns: 10% 1fr;
      padding-bottom: 2rem;
      border-bottom: 1px solid #d5d5db;
      @media (min-width: $breakpoint-tablet) {
        padding-bottom: 1rem;
      }

      * {
        color: #000;
      }
      h3 {
        text-align: center;
        text-transform: uppercase;
        font-size: 12px;
        letter-spacing: 1px;
        line-height: 18px;
      }
      svg {
        path {
          fill: black !important;
        }
      }
      @media (min-width: $breakpoint-tablet) {
        svg {
          visibility: hidden;
        }
      }
    }
  }

  .footer-button {
    margin-bottom: -2rem;
    margin-left: -1rem;
    margin-right: -1rem;
    justify-content: center;
    @media (min-width: $breakpoint-tablet) {
      display: flex;
      > div {
        padding: 0 10px;
      }
    }
    .button {
      &-download {
        cursor: pointer;
        background-color: #242242;
        box-shadow: 0 -6px 20px 0 rgba(0, 0, 0, 0.11);
        color: #ffffff;
        font-size: 12px;
        letter-spacing: 1px;
        line-height: 10px;
        text-transform: uppercase;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 48px;
        width: 44%;
        margin-right: 1px;
      }
      &-delete-all {
        cursor: pointer;
        background-color: #fff;
        color: #242242;
        font-size: 12px;
        letter-spacing: 1px;
        line-height: 10px;
        text-transform: uppercase;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 48px;

        img {
          height: 50%;
        }
      }
    }
  }
}
