@import "styles/_variables";

.search-bar-container {
  height: 48px;
  margin-bottom: 34px;
  display: grid;
  grid-template-columns: 10% 1fr 10%;
  border: 0.5px solid #ededed;
  border-radius: 4px;
  justify-items: center;
  align-items: center;
  .search-bar {
    font-family: var(--elliot-regular);
    display: block;
    border: none;
    font-size: 16px;
    line-height: 2;
    color: #979797;
    background-color: transparent;
    width: 100%;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &:focus,
    &:active {
      outline: none;
    }
    &::placeholder {
      line-height: 2;
      color: #fff;
    }
  }

  #input-search:focus::placeholder {
    transition: color 0.5s;
    color: transparent;
  }

  .search-icon {
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
    display: flex;
    img {
      filter: var(--filter-black);
      width: 16px;
      height: 16px;
    }
  }
  .search-clear {
    width: 12px;
    height: 12px;
    transform: translateY(-50%);
    cursor: pointer;
    padding: 2px;
    svg {
      width: 100%;
      height: 100%;
    }
  }

  @media (min-width: $breakpoint-tablet) {
    .search-bar-container {
      margin-bottom: 40px;
      .search-bar {
        height: auto;
        padding: 4px 51px 10px;
        font-size: 20px;
        line-height: 2;
        &::placeholder {
          overflow: visible;
        }
      }

      .search-icon {
        width: 23px;
        height: 23px;
      }
      .search-clear {
        width: 17px;
        height: 17px;
        right: 21px;
      }
    }
  }
}

@media (min-width: $breakpoint-tablet) {
  .header {
    height: 175px;
    margin-bottom: 40px;
  }
}
