@import "styles/_variables";
@import "styles/_mixin";

.title-container-wrapper {
  box-sizing: border-box;
  width: calc(100% + 64px);
  margin-left: -32px;
  padding: 32px;
  background: #f7f8f9;
  padding-top: 6rem;

  &.blue-image {
    @include background-image-blue;
  }
  &.gray-image {
    background: url("../../images/Quals background shape.svg") no-repeat left
      bottom;
  }

}

@media (min-width: $breakpoint-tablet) {
  .title-container-wrapper {
    width: calc(100% + 128px);
    margin-left: -64px;
    padding: 72px 64px 56px;
    padding-top: 15rem;
  }
}
