@import "styles/_variables";

.accordion-container {
  border-bottom: 1px solid #00000016;
  .accordion-header {
    position: relative;
    color: black;
    padding: 18px 42px 9px 0;
    height: 55px;
    &.open {
      font-family: var(--elliot-bold);
    }
    img {
      position: absolute;
      top: 21px;
      right: 5px;
      width: 6px;
      transform: rotateZ(90deg);
      transition: transform 0.3s;
      &.open {
        transform: rotateZ(270deg);
      }
    }
  }
  .accordion-content-container {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.3s;
    .accordion-content {
      padding-bottom: 33px;
    }
    .accordion-content > * {
      color: #909090;
      line-height: 24px;
      b {
        color: #909090;
        font-family: var(--elliot-bold);
      }
    }
  }
}

@media (min-width: $breakpoint-tablet) {
  .accordion-container {
    .accordion-content-container {
      .accordion-content {
        padding-bottom: 41px;
      }
      .accordion-content > * {
        max-width: 85%;
        font-size: 18px;
        line-height: 28px;
      }
    }
  }
}
