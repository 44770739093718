.group-detail {
  .enabled {
    background-color: white!important;
    border: solid 0.5px #8C8E90!important;
  }
  .sync {
    background-color: transparent!important;
    font-weight: bold;
    letter-spacing: 1.5px;
    padding-left: 0!important;

    &-true {
      color: #3289ED!important;
    }

    &-false {
      color: #FF0000!important;
    }
  }

  .add-user {
    margin-bottom: 0.5rem;
  }

  .bo-form-code-value {
    color: black;
    font-family: var(--elliot-bold);
    letter-spacing: 2px;
    font-size: 20px;
  }

}