@import "styles/_variables";

.header-container {
  display: grid;
  grid-template-columns: 15% 1fr 15%;
  align-items: center;
  justify-items: center;
  padding: 2rem;
  position: relative;
  z-index: 101;

  .profile {
    display: none;
    grid-column: 2/3;
    grid-row: 1/2;
    cursor: pointer;
  }

  .menu {
    position: fixed;
    height: 100vh;
    overflow: auto;
    top: 0;
    width: 100%;
    left: -100%;
    transition: left 0.5s ease-in-out;
    &.visible {
      left: 0;
      z-index: 100;
    }
  }

  .logo {
    display: flex;
    justify-content: center;
    cursor: pointer;
    grid-column: 2/3;
    img {
      width: 10rem;
    }
  }
  .logo.logo-bip {
    padding: 0;
    margin-bottom: -2rem;
    img {
      margin-top: -0.8rem;
      margin-left: -5rem;
      width: 4rem;
      @media (min-width: $breakpoint-tablet) {
      width: 5rem;
      }
    }
  }

  &.not-homepage {
    background-color: transparent;
    div:not(.logo) {
      svg {
        fill: #3c3c3c;
        path,
        rect {
          fill: #3c3c3c;
        }
      }
      img {
        filter: var(--filter-black);
      }
      input::placeholder {
        color: #3c3c3c;
      }
    }
    &.reverse-variant {
      div :not(.logo) {
        svg {
          fill: #fff;
          path,
          rect {
            fill: #fff;
          }
        }
        img {
          filter: var(--filter-white);
        }
      }

      .header-search {
        input {
          color: #ffffff;
          &::placeholder {
            color: #fff;
          }
        }
      }
      .profile {
        .info {
          .name {
            color: #fff;
          }
          .open-down {
            svg {
              fill: #fff;
            }
          }
        }
      }
      .menu {
        .menu-items {
          .menu-item {
            span {
              color: #fff;
            }
          }
          .menu-spacer {
            border-color: #fff;
          }
        }
      }
      .burger-container {
        span {
          background-color: #fff !important;
        }
      }
    }
  }

  .burger {
    grid-column: 1/2;
    justify-self: start;
  }

  .bookmarks {
    justify-self: end;
    grid-column: 3/4;
    grid-row: 1/2;
    position: relative;
    cursor: pointer;
    .search-len {
      width: 1rem;
      height: 1rem;
      position: absolute;
      top: 0;
      right: 2rem;
      cursor: pointer;
      svg {
        fill: white;
        path,
        rect {
          fill: #fff;
        }
      }
    }
  }

  .header-search {
    display: none;
    grid-row: 2/3;
    grid-column: 1/-1;
    width: 100%;
    padding: 1.5rem 0;
    .search-bar-container {
      margin-bottom: 0;
    }
    &.is-search-page {
      display: block;
    }
  }
  @media (min-width: $breakpoint-tablet) {
    grid-template-columns: 1fr 15% 4%;
    padding: 0;
    > div:not(.menu) {
      padding: 1rem;
    }

    .burger,
    .logo,
    .header-search {
      display: none;
    }
    .menu {
      position: relative;
      grid-row: 1/1;
      display: block;
      left: 0;
      height: initial;
      justify-self: start;
    }

    .profile {
      display: block;
      justify-self: end;
    }
    .bookmarks {
      grid-column: 3/4;
      .search-len {
        display: none;
      }
    }

    &.not-homepage {
      grid-template-columns: 15% 1fr 25% 2%;
      .burger {
        display: none;
      }
      .logo {
        display: block;
        grid-column: 1/2;
      }
      .header-search {
        display: block;
        grid-row: unset;
        grid-column: 2/3;
        max-width: 40%;
      }
      .bip-logo {
        grid-column: 1/4;
        background-image: url("../../images/bip.png");
      }
      .profile {
        grid-column: 3/4;
        .name,
        .open-down svg {
          color: var(--black);
          fill: var(--black);
        }
      }
      .bookmarks {
        grid-column: 4/5;
      }
      .menu {
        .menu-container {
          background-color: #f7f8f9;
        }

        grid-column: 1/-1;
        grid-row: 2/3;
        //background-color: #eef1f3;

        .menu-item span,
        .menu-spacer {
          color: var(--black);
          border-color: var(--black);
        }
      }
    }
    &.reverse-variant {
      .menu {
        .menu-container {
          background-color: #0d24386c;
        }
      }
    }
  }
}
