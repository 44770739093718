@import "styles/_variables";
.bipunit {
  &-header {
    display: grid;
    grid-template-columns: 5rem 1fr;
    align-items: center;
    @media (min-width: $breakpoint-tablet) {
      grid-template-columns: 8rem 1fr;
    }

    .logo-img {
      img {
        width: 55px;
        border-radius: 50%;
        height: 55px;
        @media (min-width: $breakpoint-tablet) {
          width: 96px;
          height: 96px;
        }
      }
    }
    .title-section {
      padding: 1.5rem 0.5rem;
      .type {
        color: #0c2340;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 1px;
        line-height: 18px;
        text-transform: uppercase;
        @media (min-width: $breakpoint-tablet) {
          font-size: 14px;
          font-weight: bold;
          letter-spacing: 1px;
          line-height: 20px;
        }
      }
      .title {
        color: #000000;
        font-size: 32px;
        letter-spacing: 0;
        line-height: 32px;
        @media (min-width: $breakpoint-tablet) {
          font-size: 56px;
          letter-spacing: 0;
          line-height: 64px;
        }
      }
    }
  }
  &-wrapper {
    @media (min-width: $breakpoint-tablet) {
      display: flex;
      justify-content: space-between;
      padding-top: 2rem;
      padding-bottom: 6rem;

      > div:first-child {
        flex-basis: 40%;
      }
      > div.top {
        flex-basis: 40%;
        align-self: flex-start !important;
      }
    }
  }
  &-description {
    padding: 2rem 0;
    @media (min-width: $breakpoint-tablet) {
      padding: 4rem 0;
    }
    .desc {
      color: #3c3c3c;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 24px;
      @media (min-width: $breakpoint-tablet) {
        color: #353535;
        font-size: 18px;
        letter-spacing: 0;
        line-height: 28px;
      }
    }
    .tags {
      margin: 1rem 0;
      display: flex;
      flex-wrap: wrap;
      .tag {
        margin: 0 0.5rem;
        font-size: 14px;
        color: #768292;
        @media (min-width: $breakpoint-tablet) {
          color: #7c899a;
          font-size: 18px;
          letter-spacing: 0;
          line-height: 18px;
        }
      }
    }
    .subtitle {
      font-size: 18px;
      color: #0c233f;
      @media (min-width: $breakpoint-tablet) {
        font-size: 24px;
        letter-spacing: 0;
        line-height: 32px;
      }
    }
  }

  &-mission {
    @media (min-width: $breakpoint-tablet) {
      padding: 4rem 0;
    }
    .title {
      color: #000000;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 1px;
      line-height: 18px;
      @media (min-width: $breakpoint-tablet) {
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 1.17px;
        line-height: 18px;
        border-left: 2px solid #e40046;
        padding-left: 1rem;
        padding-top: 1rem;
      }
    }
    .mission {
      color: #0c233f;
      font-size: 18px;
      letter-spacing: 0;
      line-height: 24px;
      border-left: 2px solid #e40046;
      padding-left: 1rem;
      padding-top: 1rem;
      @media (min-width: $breakpoint-tablet) {
        color: #353535;
        font-size: 24px;
        letter-spacing: 0;
        line-height: 32px;
      }
    }
  }

  &-types {
    display: flex;
    padding: 1rem 0;
    flex-wrap: wrap;
    align-items: center;

    .type {
      border-radius: 15px;
      background-color: #e5e7ea;
      padding: 0.3rem 1rem;
      margin: 0.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      span {
        display: block;
        color: #0c233f;
        font-size: 10px;
        font-weight: bold;
        letter-spacing: 0.83px;
        line-height: 10px;

        @media (min-width: $breakpoint-tablet) {
          font-size: 12px;
          font-weight: bold;
          letter-spacing: 1px;
          line-height: 10px;
        }
      }
    }
  }

  &-achievements {
    margin: 0 -2rem;
    padding: 1rem 2rem;
    background-color: #0d243f;

    @media (min-width: $breakpoint-tablet) {
      margin: 0 -4rem;
      padding: 4rem 4rem;
      min-height: 18rem;
    }

    .title {
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 1px;
      line-height: 18px;
      @media (min-width: $breakpoint-tablet) {
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 1.33px;
        line-height: 18px;
      }
    }
    .archs {
      @media (min-width: $breakpoint-tablet) {
        display: flex;
        align-items: center;
      }
    }
    .arch {
      padding: 1rem 0;

      .value {
        font-size: 72px;
        letter-spacing: 0;
        line-height: 64px;
        @media (min-width: $breakpoint-tablet) {
          font-size: 88px;
          letter-spacing: 0;
          line-height: 64px;
        }
      }
      .description {
        color: #d3d3d3;
        font-size: 16px;
        letter-spacing: 0;
        line-height: 22px;
        @media (min-width: $breakpoint-tablet) {
          font-size: 18px;
          letter-spacing: 0;
          line-height: 28px;
        }
      }
      @media (min-width: $breakpoint-tablet) {
        max-width: 18rem;
        min-height: 8rem;
        margin: 2rem 3rem;
      }
    }
  }
  &-references {
    background-color: #f7f8f9;
    margin: 0 -2rem;
    padding: 4rem 2rem;
    @media (min-width: $breakpoint-tablet) {
      margin: 0;
    }
    .title {
      color: #000000;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 1px;
      line-height: 18px;
    }
  }

  &-offering-topics {
    padding: 2.5rem 0;
    @media (min-width: $breakpoint-tablet) {
      padding: 2.5rem;
    }
    .title {
      color: #000000;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 1px;
      line-height: 18px;
      padding-bottom: 1rem;
    }
    .text {
      color: #3c3c3c;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 24px;
    }
  }
  &-main-clients {
    background-color: #f7f8f9;
    margin: 0 -2rem;
    padding: 4rem 2rem;
    @media (min-width: $breakpoint-tablet) {
      margin: 4rem 0;
    }
    .title {
      color: #000000;
      font-size: 12px;
      font-weight: bold;
      letter-spacing: 1px;
      line-height: 18px;
      padding-bottom: 1rem;
    }

    .img-customers {
      &-container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
        @media (min-width: $breakpoint-tablet) {
          justify-content: flex-start;
        }
      }
      &-wrapper {
        flex-basis: 40%;
        padding: 1rem;
        @media (min-width: $breakpoint-tablet) {
          flex-basis: 30%;
        }
        img {
          width: 100%;
        }
      }
    }
  }

  .CKEditor-container {
    color: black !important;

    span {
      color: black !important;
    }

    p {
      color: black !important;
    }

    strong {
      color: black !important;
    }

    i {
      color: black !important;
    }

    u {
      color: black !important;
    }

    p::before {
      content: none !important;
    }

    li {
      color: black !important;
    }

    label {
      display: none !important;
    }

    .ck-editor__editable {
      padding-left: 20px !important;
    }

    .text-big {
      font-size: 1.25rem;
    }

    .text-tiny {
      font-size: 0.85rem;
    }

    .ck-fontsize-option {
      font-size: 1rem;
    }

    .ck.ck-editor__editable > .ck-placeholder::before {
      color: #d21714;
    }

    li::marker {
      padding-left: 10px;
      color: black;
      //font-size: 20px;
      margin-left: 20px;
    }
  }

  &-drop-container {
    display: flex;
    flex-direction: column;
    padding: 4rem 0;
  }

  &-drop {
    display: flex;
    padding: 32px 88px;
    background-color: #f7f8f9;
    justify-content: center;
    align-items: center;
    margin-bottom: 200px;
    flex-direction: column;

    & .scroll-down {
      height: auto;
      max-height: 400px;
      overflow-y: auto;
      overflow-x: hidden;
      min-width: 100%;
      max-width: 100%;
      padding-right: 0;
      margin: 0;
      flex-wrap: wrap;
      justify-content: space-evenly;
      margin-bottom: 54px;
    }

    &-header {
      color: #000 !important;
      font-size: 13px;
      font-weight: bold;
      line-height: 32px;
      text-transform: uppercase;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 20px;
      @media (min-width: $breakpoint-tablet) {
        font-size: 20px;
      }
    }

    & .level {
      background-color: #fff;
      margin-bottom: 10px;

      & .label {
        font-size: 12px;
        @media (min-width: $breakpoint-tablet) {
          font-size: 14px;
        }
      }

      & .level-marker {
        display: flex;
        color: #da291c;
        width: 30px;
        height: 35px;
        background-color: #fff;
        justify-content: center;
        align-items: center;
        font-size: 38px;
        margin-right: 9px;
        cursor: pointer;
      }

      &-children {
        &-1 {
          padding-left: 15px;
          margin-left: 15px;
          position: static;
        }
        &-2 {
          padding-left: 30px;
          margin-left: 30px;
          position: static;
        }
        &-3 {
          padding-left: 45px;
          margin-left: 45px;
          position: static;
        }
      }
    }

    &-description {
      color: #000;
    }

    & .action {
      & .view-more {
        height: 40px;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 1px;
        line-height: 21px;
        text-align: center;
        background-color: #3c4e65;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50px;
        cursor: pointer;
        color: #fff;
        margin-top: 20px;
        margin-bottom: 8px;
        padding: 13px 8px;
        &.disabled {
          pointer-events: none;
          opacity: 0.4;
        }
      }
    }

    & .categories-container {
      margin-right: 0;
      margin-left: 0;
      max-width: 300px;

      & .filter-number {
        width: 1.8rem;
        height: 1.8rem;
        font-size: 14px;
        @media (min-width: $breakpoint-tablet) {
          width: 2rem;
          height: 2rem;
          font-size: 18px;
        }
      }
      @media (min-width: $breakpoint-tablet) {
        max-width: 600px;
      }
    }
  }
}
