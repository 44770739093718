@import "styles/_variables";

.filters-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;

  .filter-bar {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    margin-top: 16px;

    .filter-settings {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 1px solid #0c234080;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      .filter-icon {
        width: 40px;
        height: 40px;
        cursor: pointer;
      }
      .filters-number {
        position: absolute;
        top: -10px;
        right: -10px;
        background-color: #0c2340;
        border-radius: 50%;
        padding: 0.2rem;
        width: 1.3rem;
        height: 1.3rem;
        font-size: 15px;
        display: flex;
        justify-content: center;
      }
    }

    & .select-container {
      display: flex;
      align-items: center;
    }
  }

  & .results-count {
    display: none;
    & .results-label {
      color: var(--black);
    }
  }

  .sort-select {
    color: #0E4576;
    option {
      color: inherit;
    }
  }

  @media (min-width: $breakpoint-tablet) {
    flex-direction: row;
    height: 56px;
    margin-bottom: 40px;

    > * {
      width: auto;
      height: 56px;
      margin: 0;
      padding: 0;
    }
    .results-count {
      display: flex;
      align-items: center;
      margin-right: auto;
      font-family: var(--elliot-regular);
      opacity: 0.61;
    }

    .filter-bar {
      margin: 0;
      justify-content: flex-end;
      .filter-settings {
        display: none;
      }
      .search-container {
        width: calc(33.333333% - 22.333333px);
        .label {
          font-size: 16px;
          letter-spacing: 1.33px;
        }
        select {
          font-size: 16px;
          letter-spacing: 1.33px;
          * {
            color: #0c233f;
          }
        }
      }
    }
  }
}
.filter-chips {
  display: none;
  @media (min-width: $breakpoint-tablet) {
    display: flex;
    padding: 1rem 0;
    align-items: center;
    flex-wrap: wrap;
    .chip {
      font-family: var(--elliot-bold);
      font-size: 12px;
      letter-spacing: 1px;
      line-height: 10px;
      position: relative;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      height: 30px;
      border-radius: 17.5px;
      border: 0.7px solid #0d243f;
      padding: 12px 32px 12px 12px;
      margin-right: 16px;
      margin-bottom: 8px;
      .chip-label {
        color: #0d243f;
      }
    }
    .chip:last-child {
      margin-right: 0;
    }
    .chip .chip-clear {
      position: absolute;
      right: 12px;
      cursor: pointer;
    }
  }
}

.filter-bar-serp {
  box-sizing: border-box;
  width: calc(100% + 64px);
  margin-left: -32px;
  height: 72px;
  padding: 0 32px;
  background: #f7f8f9;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  border-top: 1px solid #00000018;

  & .select-container {
    display: flex;
  }

  @media (min-width: $breakpoint-tablet) {
    height: 80px;
    justify-content: flex-end;
    margin-bottom: 82px;
    & .filter-settings {
      display: none;
    }
    & .select-container .label {
      font-size: 16px;
      letter-spacing: 1.33px;
    }
    & .select-container select {
      font-size: 16px;
      letter-spacing: 1.33px;
    }
  }
}
