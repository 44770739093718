@import "styles/_variables";

.how-to__section {
  padding-top: 3rem;
  padding-bottom: 2.75rem;

  & ~ .how-to__section {
    padding-top: 2.75rem;
  }

  .heading_one {
    margin: 0.5rem 0 0 0;
  }
  .type {
    margin-bottom: 0.5rem;
  }

  .how-to__section__intro {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    position: relative;
    padding-bottom: 0.5rem;
  }

  .how-to__section__content {
    margin-top: 1.5rem;
  }

  &.how-to__section-with-stickers {
    .how-to__section__sticker-end {
      display: none;
    }
  }

  @media (min-width: $breakpoint-tablet) {
    padding-top: 5.125rem;
    padding-bottom: 5rem;
    & ~ .how-to__section {
      padding-top: 5rem;
    }
    .heading_one {
      margin: 0.5rem 0 3rem 0;
    }
    .how-to__section__intro {
      flex-direction: row;
      justify-content: space-between;
      padding-bottom: 0;
      .how-to__section__intro__content {
        padding-right: 364px;
      }
    }
    .how-to__section__content {
      margin-top: 3rem;
    }
    &.how-to__section-with-stickers {
      .regular {
        padding-right: 364px;
        max-width: calc(65% + 364px);
      }
      .how-to__section__content {
        .regular {
          padding-right: 0;
          max-width: 100%;
        }
      }
      .how-to__section__sticker-end {
        display: block;
        object-fit: cover;
        position: absolute;
        top: 0;
        right: 0;
        max-width: 364px;
      }
    }
  }
}

