@import "styles/_variables";

/* style for comments number under the content title */
.total-comments {
  font-size: 20px;
  line-height: 20px;
  color: #000000;
  text-decoration: underline;
  padding-top: 6px;
  font-weight: 400;
  cursor: pointer;
}

.comments-container {
  background-color: #F7F8F9;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-left: -2rem;
  width: calc(100% + 4rem);
  padding: 0 2rem;

  .comments-section-header {
    border-bottom: #979797 solid 1px;
    display: flex;
    justify-content: center;
    width: 100%;
    flex-direction: column;
    min-height: 90px;
    margin-bottom: 15px;

    .comments-section-title {
      display: flex;
      align-items: center;
      flex-direction: column;

      .title {
        line-height: 28px;
        margin-top: 10px;
      }
    }

    .comments-section-link {
      color: #0E4576;
      text-decoration: underline;
      line-height: 30px;
      cursor: pointer;
    }
  }

  .comments-number {
    color: #6B6B6B;
    font-size: 18px;
    font-width: 400;
    padding-bottom: 4rem;

    .likes-number {
      color: #6B6B6B;
      font-size: 18px;
      font-family: var(--elliot-bold);
      font-weight: bold;
    }
  }

  .comments-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .load-button-container {
    display: flex;
    justify-content: center;

    .load-more-button {
      height: 50px;
      border-radius: 10px;
      border: 1px solid #6F7A8D;
      width: fit-content;
      margin-bottom: 80px;
      background-color: transparent;

      span {
        color: #6F7A8D;
        padding: 0 10px;
      }
    }
  }
}

@media (min-width: $breakpoint-tablet) {
  .comments-container {
    margin-left: -4rem;
    width: calc(100% + 8rem);
    padding: 0 7rem;
    flex-wrap: nowrap;
  }

  .comments-section-header {
    flex-direction: row !important;
    align-items: center;
    justify-content: space-between !important;
  }

  .comments-section-title {
    flex-direction: row !important;
  }
}

