@import "styles/_variables";

.tree-view-modal{
  color: black !important;
  border: 1px solid red;
  & .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 200;
  }

  & .modal-main {
    position: fixed;
    background: #fff;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: scroll;
  }

  & .modal-header {
    position: relative;
    border-bottom: 1px solid #0002;
    margin-bottom: 27px;
    padding-bottom: 27px;
    padding-left: 27px;
    & .modal-title {
      font-size: 40px;
      color: #353535;
      font-family: var(--elliot-regular);
      margin: 0;
      line-height: 64px;
      span {
        color: #353535;
        font-size: 30px;
      }
    }
    & .modal-title-value {
      font-size: 40px;
      color: #353535;
      font-family: var(--elliot-bold);
      margin: 0;
      line-height: 64px;
    }
    & .modal-header-close {
      cursor: pointer;
      position: absolute;
      top: 2rem;
      right: 2rem;
      & img {
        height: 20px;
      }
    }
    @media (max-width: $breakpoint-mobile) {
      padding-right: 35px;
      & .modal-header-close {
        top: 1rem;
        right: 1rem;
      }
    }
  }

  & .modal-container {
    margin: 0 65px;
    @media (max-width: $breakpoint-tablet) {
      margin: 0 10px;
    }

    & .action {
        width: 41%;
      & .add-item {
        height: 100%;
        padding: 5px;
      }
    }
    @media (max-width: $breakpoint-laptop) {
      & .action {
        width: 52%;
      }
    }
  }

  & .modal-description {
    color: #fff;
    background-color: red;
    padding: 8px;
    margin-bottom: 16px;
    font-size: 20px;
    text-transform: uppercase;
    width: fit-content;
  }


  @media(min-width: $breakpoint-mobile) and (max-width: $breakpoint-tablet) {
    & .results-container{
      & .categories-container{
        & .drop{
          .level{
            padding-left: 60px;
          }
        }
      }
    }
  }

  @media (max-width: $breakpoint-mobile) {
    & .results-container {
      flex-wrap: wrap;
    }

    & .results-list-contributor{
      width: 100%;

      &-header{
        margin: 30px -10px 40px;
        width: 100vw;
        font-size: 27px;
        justify-content: space-between;
      }
      .drag {
        height: auto;
        padding: 0 calc(2rem - 10px);
      }
    }

    & .categories-container {
      width: 100%;
      margin: 0 15px 0 -10px;
    }

    & .offering-card-contributor{
      min-height: 230px;
      padding: 15px 10px;

      .name{
        padding-top: 10px;
        align-items: flex-start;
      }

    }

  }

  @media (max-width: $breakpoint-mobile-mini) {
    & .results-list-contributor{
      &-header{
        font-size: 19px;
      }
    }
  }

  @media (min-width: $breakpoint-mobile-mini) and (max-width: 360px) {
    & .results-list-contributor{
      &-header{
        font-size: 23px;
      }
    }
  }

}
