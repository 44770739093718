@import "styles/_variables";

.links-section {
  padding-top: 3.5rem;

  .regular {
    padding: 1rem 0 1.5rem 0;
  }

  .links-demo {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }

  @media (min-width: $breakpoint-tablet) {
    padding-top: 5.5rem;
    .regular {
      padding: 1rem 0 3rem 0;
    }
    .links-demo {
      display: grid;
      grid-template-columns: calc(25% - 24px) calc(25% - 24px) calc(25% - 24px) calc(25% - 24px);
      grid-column-gap: 32px;

      .myracle-link {
        width: auto;
        font-family: var(--elliot-bold);
      }
    }
  }
}
