.color-bar-container {
  display: flex; /* Imposta il contenitore dei div come flex container */
}

.color-bar {
  height: 12px;
  position: relative;
}
@media screen and (min-width: 992px) {
  .color-bar:hover {
    height: 20px;
    bottom: 8px;
    margin-bottom: -8px;
  }
}

.color-bar-tooltip {
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.0025);
}
@media screen and (max-width: 992px) {
  .color-bar-tooltip {
    display: none !important;
  }
}
