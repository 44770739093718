@import "styles/_variables";

.events {
    padding-top: 32px;
    padding-bottom: 48px;
    background-color: #051325;
    position: relative;

    & .title {
        font-size: 12px;
        font-family: var(--elliot-pro);
        letter-spacing: 1px;
        line-height: 18px;
        text-align: center;
        margin-bottom: 47px;
    }

    & .events-list {
        display: flex;
        align-items: stretch;
        flex-direction: column;
        justify-content: flex-start;
        margin-bottom: 48px;

        & .divider {
            height: 1px;
            margin: 16px 0px;
            background: #c4c4c4;
        }
    }

    & .view-all-container {
        display: flex;
        justify-content: center;
        align-items: center;

        & a {
            font-family: var(--elliot-pro);
            font-size: 12px;
            letter-spacing: 1px;
            line-height: 18px;
        }
    }
}

/* Desktop Min Width 900 */

@media (min-width: $breakpoint-tablet) {
    .events {
        background-color: transparent;
        position: relative;

        & .title {
            box-sizing: border-box;
            margin: 0 auto;
            width: 760px;
            padding: 41px;
            padding-bottom: 13px;
            font-size: 16px;
            letter-spacing: 1.33px;
            background: #09192fcd;
            text-align: initial;
        }

        & .events-list {
            box-sizing: border-box;
            margin-left: auto;
            margin-right: auto;
            width: 760px;
            padding: 41px;
            background: #09192fcd;
            & .divider {
                margin: 22px 0;
            }
        }
    }

    .event-entry .event-date {
        font-size: 14px;
        letter-spacing: 1.17px;
        margin-bottom: 8px;
    }

    .event-entry .event-status {
        margin-left: 36px;
    }

    .event-entry .event-name {
        font-size: 24px;
        margin-bottom: 24px;
    }

    .event-entry .event-manager {
        font-size: 18px;
    }

    .events .view-all-container {
        box-sizing: border-box;
        position: absolute;
        top: 32px;
        left: calc(50% - 380px);
        width: 760px;
        padding: 41px;
        justify-content: flex-end;
    }

    .events .view-all-container a {
        font-size: 16px;
        letter-spacing: 1.33px;
    }
}
