@import "styles/_variables";

.container-how-to {
  .downloads {
    .buttons-section:first-child {
      padding-bottom: 3.5rem;
    }
  }

  .myracle-videos {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex: 1 0 100%;
    gap: 1rem;
    row-gap: 2rem;
  }

  .contact-cards {
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
  }

  .container-how-to__bottom-stickers {
    display: flex;
    justify-content: center;
    column-gap: 3rem;
    padding-top: 0.25rem;
    padding-bottom: 1rem;
    img {
      object-fit: cover;
    }
  }

  @media (min-width: $breakpoint-tablet) {
    .downloads {
      .buttons-section:first-child {
        padding-bottom: 5.5rem;
      }
    }

    .contact-cards {
      flex-direction: row;
      row-gap: 0;
      column-gap: 2rem;
      justify-content: space-between;
      .contact-card-container {
        width: calc(50% - 16px);
      }
    }

    .container-how-to__bottom-stickers {
      display: none;
    }

    .myracle-videos > :nth-child(-n + 3) {
      flex: 1 0 calc(33.33% - 20px); /* adjust the subtraction value as needed for the gap */
      max-width: 30%;
    }

    .myracle-videos > :nth-child(n + 4) {
      flex: 1 0 calc(50% - 20px); /* adjust the subtraction value as needed for the gap */
      max-width: 30%;
    }
  }
}
