@import "styles/_variables";

.custom-datepicker-wrapper {
  margin-bottom: 1.4rem;
  position: relative;
  padding: 0 0.3rem;

  .react-datepicker__input-container {
    input {
      font-family: var(--elliot-regular);
      border: none;
      border-bottom: 1px solid #52697b;
      height: 48px;
      font-size: 18px;
      color: var(--black);
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      cursor: pointer;
      width: 100%;
    }
  }

  .custom-datepicker-arrow {
    @media (min-width: $breakpoint-tablet) {
      transform: rotate(90deg);
      margin-right: 0.3rem;
      display: none;
    }
  }
}
