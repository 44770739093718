@import "styles/_variables";

/*
.errors-management-tabs-container {
  flex-direction: column;
  height: auto;
  position: relative;

  .tabs-bar {
    display: flex;
    justify-content: center;

    .tab {
      background-color: var(--white);
      height: 40px;
      margin: 0;
      padding: 0 20px;
      border: 1px solid var(--menu-bg);
      color: var(--menu-bg);

      &.active {
        background-color: var(--menu-bg);
        color: var(--white);
      }
    }
  }

  .tabs-content {
    border-top: 1px solid var(--menu-bg);
    position: absolute;
    left: 0;
    right: 0;
    top: 39px;

    .hide-content {
      display: none;
    }

    .show-content {
      display: block;
    }
  }
}
 */

.custom-tabs {
  .tabs-container {
    width: 100%;
    margin-left: -4px;
    display: flex;
    justify-content: center;
    align-items: stretch;
    height: 27px;
    overflow-x: scroll;

    -ms-overflow-style: none; /* HIDE SCROLLBAR */
    scrollbar-width: none; /* HIDE SCROLLBAR */

    &::-webkit-scrollbar {
      display: none; /* HIDE SCROLLBAR */
    }

    & .tab {
      font-family: var(--elliot-regular);
      font-size: 12px;
      letter-spacing: 1px;
      line-height: 14px;
      color: #050505;
      margin-right: 20px;
      flex-shrink: 0;
      text-decoration: none;
      cursor: pointer;
    }
  }

  .tab.selected {
    font-family: var(--elliot-bold);
    border-bottom: 4px solid #0c233f;
  }

  .tab-content {
    display: none;

    &.visible-tab {
      display: block;
    }
  }
}
