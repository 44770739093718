.back-link {
  text-decoration: none;
  display: flex;
  align-items: center;
  font-size: 14px;
  letter-spacing: 1px;
  line-height: 20px;
  cursor: pointer;
  height: 2rem;
  margin-top: 27px;

  & span {
    color: #0c2340;
  }
  & img {
    margin: 0 14px 0 12px;
    transform: scaleX(-1);
  }
}
