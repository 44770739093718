@import "styles/_mixin";

.load-app {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;

  @include background-image-blue;
  .wrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    height: 15rem;
  }
  svg {
    width: 600px;
    max-width: 100%;
  }

  h1 {
    font-family: var(--elliot-heavy);
  }
}
