.bip-units-legend {
  display: flex;
  flex-direction: row;
}

.bip-units-legend > * {
  display: flex;
  flex-direction: row;
  color: black;
  margin-right: 3rem;

  .color-legend {
    min-width: 10px;
    width: 1.2rem;
    height: 1.2rem;
    border-radius: 50%;
    color: black;
    margin-right: 0.7rem;
  }
}

@media (max-width: 744px) {
  .bip-units-legend {
    display: none;
  }
}
