@import "styles/_variables";

.clientpersona-card {
  border: 0.5px solid #979797;
  margin: 1rem 0;
  display: grid;
  grid-template-columns: 3rem 1fr 1rem;
  grid-template-rows: 1fr 5rem;
  height: 13rem;
  cursor: pointer;
  padding: 1rem;

  @media (min-width: $breakpoint-tablet) {
    grid-template-columns: 5rem 1fr 1rem;
    margin: 0;
  }

  .logo-img {
    padding: 0;
    img {
      width: 46px;
      border-radius: 50%;
      height: 46px;
    }
  }
  .title {
    padding: 1rem 1.3rem;
    color: #000000;
    font-size: 24px;
    letter-spacing: 0;
    line-height: 22px;
    @media (min-width: $breakpoint-tablet) {
      font-size: 30px;
      letter-spacing: 0;
      line-height: 32px;
    }
  }

  .cta {
    padding: 0;
    align-self: center;
    img {
      filter: invert(16%) sepia(97%) saturate(4586%) hue-rotate(333deg)
        brightness(85%) contrast(112%);
      width: 100%;
    }
    svg {
      width: 100%;
      height: 100%;
      rect {
        fill: #e40046;
        stroke: #e40046;
      }
    }
  }
  .description {
    grid-column: 1/-1;
    opacity: 0.7;
    color: #000000;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;

    background-color: #f7f8f9;
    margin-left: -0.9rem;
    margin-bottom: -0.9rem;
    margin-right: -0.9rem;
    padding: 1rem;

    @media (min-width: $breakpoint-tablet) {
      font-size: 18px;
      letter-spacing: 0;
      line-height: 28px;
    }
  }
}
