html {
  font-size: 100%;
}

* {
  color: white;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: var(--elliot-regular), sans-serif;
  font-weight: 400;
}

.container {
  position: absolute;
  top: 0;
  width: 100%;
  padding: 0 2rem;
  &.detail {
    padding-top: 0;
    z-index: 150;
    background-color: var(--white);
    @media (min-width: $breakpoint-tablet) {
      padding-top: 15rem;
      z-index: initial;
      background-color: initial;
    }
  }
  @media (min-width: $breakpoint-tablet) {
    padding: 0 4rem;
  }
}

.others-cards-list {
  margin-top: 2rem;
  margin-bottom: 4rem;

  @media (min-width: $breakpoint-tablet) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 1rem;
    row-gap: 2rem;
    justify-content: center;
  }
  @media (min-width: 1360px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: 1800px) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.list-industries {
  margin-top: 2rem;
  margin-bottom: 4rem;
}

.contributor {
  .results-cards.scroll-down {
    column-gap: 5rem;
    @media (min-width: 900px) {
      grid-template-columns: repeat(2, 360px);
    }
    @media (min-width: 1280px) {
      column-gap: 3rem;
      grid-template-columns: repeat(3, 360px);
    }
    @media (min-width: 1700px) {
      column-gap: 5rem;
      grid-template-columns: repeat(4, 360px);
    }
  }
}

.sidebar-color {
  position: absolute;
  top: 152px;
  left: 0;
  width: 12px;
  height: 100%;
  z-index: 1;
}
