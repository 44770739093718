@import "styles/_variables";

.admin {
  .contributor-header {
    margin-bottom: 0 !important;

    .title {
      grid-column: 1/3;
    }
  }

  .results-container .results {
    width: 100%;
  }

  .filters-container {
    margin-bottom: 0;
  }

  .filters-container .search-container {
    width: calc(33.333333% - 22.333333px);
    margin-left: auto;
  }

  .no-margin {
    margin: 0 !important;
  }

  .results-container {
    display: flex;
    align-items: flex-start;
    margin-top: 3rem;

    .side-filter-bar {
      flex-basis: 20%;
      margin-right: 3rem;
      display: flex;
      flex-direction: column;
      align-items: stretch;

      &.open-filter {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        position: fixed;
        z-index: 200;
        top: 0;
        right: 0;
        width: 100%;
        height: 100vh;
        background-color: #fff;
        padding: 3rem 1rem;
      }
    }
  }
}

.errors-management-search {
  display: grid;
  grid-template-columns: 25rem 1fr 20rem;
  grid-template-rows: 4rem;
  align-items: center;
  .search {
    grid-column: 3/4;
  }
}

.errors-management-results {
  .table-container {
    padding-bottom: 15px;
  }

  .error-management-export {
    padding-bottom: 100px;
    justify-content: flex-end;
    display: flex;
  }
}

.export-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  max-width: 100%;
  padding: 12px;
  height: 40px;
  background-color: #3c4e65;
  border-radius: 32px;
  text-transform: uppercase;
  cursor: pointer;
  border: none;
  outline: none;
  text-decoration: none;

  &:disabled {
    opacity: 0.2;
  }
}
