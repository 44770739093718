.iconbookmarks-container {
  width: 1rem;
  height: 1.3rem;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    filter: var(--filter-white);
  }
  .iconbookmarks-couter {
    position: absolute;
    top: -10px;
    right: -7px;
    background-color: #e40046;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 18px;
    width: 15px;
    height: 15px;
    padding: 8px;
  }
}
