@import "styles/_variables";

.comment-card {
  width: 100%;
  margin-bottom: 4rem;
  position: relative;

  .delete-button {
    position: absolute;
    top: 0;
    right: 0;
  }

  .comment-date {
    color: #6B6B6B;
    font-size: 18px;
    font-width: 400;
    font-family: var(--elliot-regular);
  }

  .bold-text {
    color: #000000;
    font-size: 18px;
    font-weight: bold;
    line-height: 24px;
    margin-bottom: 5px;
    font-family: var(--elliot-bold);
  }

  .text {
    color: #000000;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    font-family: var(--elliot-regular);
  }

  .comment-author {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;

    .name-date-container {
      margin-left: 20px;
      flex-direction: column;
      justify-content: center;
    }
  }
}

@media (min-width: $breakpoint-tablet) {
  .comment-card {
    width: 45%;
  }
}
