@import "styles/_variables";
@import "styles/_mixin";

.profile-block {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  left: 100%;
  @include background-image-blue;
  overflow: hidden;
  z-index: 1000;
  transition: all 0.5s;

  .title {
    font-family: var(--elliot-bold);
    font-size: 0.8rem;
    text-transform: uppercase;
  }

  &.visible {
    left: 0;
    z-index: 100;
  }
  .label {
    font-size: 1rem;
    min-height: 1.2rem;
  }

  .profile-info-block {
    margin-top: 9rem;
    padding: 1.5rem;
    background-color: var(--main-bg);
    opacity: 0.7;
  }
  .image-profile {
    width: 8rem;
    position: absolute;
    top: 7rem;
    img {
      border-radius: 3rem;
      width: 100%;
    }
  }
  .link-edit-block {
    display: flex;
    justify-content: flex-end;
    padding: 1rem 0;
    font-size: 0.8rem;
  }
  .infos-block {
    padding: 1.3rem 0;
    h3 {
      font-size: 1.4rem;
    }
    h4 {
      color: var(--gray);
    }
  }
  .contacts-block {
    padding: 1.3rem 0;
    border-bottom: 1px solid var(--light-gray);
    .label {
      padding: 1rem 0;
    }
  }

  .contributor {
    &-mode-block {
      padding-top: 1rem;
      padding-bottom: 1.3rem;
      border-bottom: 1px solid var(--light-gray);
      display: none;
    }

    &-link {
      padding: 1rem 0;
      display: flex;
      justify-content: space-between;
    }
    &-arrow {
      width: 20px;
      height: 20px;
      img {
        filter: var(--filter-red) !important;
      }
      svg {
        fill: #e40046 !important;
        rect,
        path {
          fill: #e40046 !important;
        }
      }
    }
  }
  .icon-back {
    position: absolute;
    top: 0;
    left: 0;
    padding: 2rem;
    transform: rotate(180deg);
    img {
      filter: var(--filter-white) !important;
    }
    svg {
      rect {
        fill: var(--white) !important;
      }
    }
  }
  .cta-section {
    padding: 1.3rem 0;
    .contributor-link .label {
      flex: 1 0 auto;
      padding-left: 1rem;
    }
  }
  .profile-card .title {
    display: none;
  }

  @media (min-width: $breakpoint-tablet) {
    position: absolute;
    top: 4rem;
    left: -5rem;
    width: 22rem;
    height: unset;
    background: none;
    background-color: var(--white);
    box-shadow: -1px 2px 7px -2px #4a4a4a;
    display: none;
    border-radius: 0.1rem;
    * {
      color: var(--black) !important;
      fill: #e40046;
    }

    &.visible {
      left: -9rem;
      display: block;
    }
    .icon-back {
      display: none;
    }
    .profile-info-block {
      background-color: var(--white);
      padding: 0;
      margin-top: 0;
      > div {
        padding: 0.5rem 1.3rem;
      }
    }
    .infos-block {
      h4 {
        color: var(--black);
      }
    }

    .title {
      display: block;
    }
    .profile-card {
      display: grid;
      grid-template-columns: 25% 1fr 15%;
      align-items: center;
      border-bottom: 1px solid var(--light-gray);
      .title {
        display: block;
        justify-self: center;
        grid-column: 1/3;
        grid-row: 1/2;
      }
      .link-edit-block {
        grid-column: 3/4;
        grid-row: 1/2;
      }
      .image-profile {
        grid-column: 1/2;
        grid-row: 2/3;
        position: relative;
        top: 0;
        left: 0;
      }
      .infos-block {
        padding: 0;
        grid-column: 2/-1;
        grid-row: 2/3;
      }
    }
    .contacts-block {
      background-color: #f3f3f3;
    }

    @media (min-width: $breakpoint-laptop) {
      .contributor-mode-block {
        display: block;
      }
    }
  }
}
