@import "styles/_variables";
.offering {
  &-trendy {
    background-color: #ffece5;
    display: flex;
    margin-top: 0;
    margin-left: -2rem;
    margin-right: -2rem;
    padding: 1rem 2rem;

    &.mobile {
      @media (min-width: $breakpoint-tablet) {
        display: none;
      }
    }
    &.desktop {
      display: none;
      @media (min-width: $breakpoint-tablet) {
        display: flex;
      }
    }

    @media (min-width: $breakpoint-tablet) {
      margin-top: -5.5rem;
    }

    .status-label {
      color: #ff4c00;
      font-family: var(--elliot-bold);
      font-size: 16px;
      text-transform: uppercase;
      margin-left: 1rem;
      margin-right: 23px;
    }

    & .status-icon-institutional {
      width: 19px;
      height: 17px;
      margin-right: -14px;
    }
  }
  &-institutional {
    background-color: #ffece5;
    display: flex;
    margin-top: 0;
    margin-left: -2rem;
    margin-right: -2rem;
    padding: 1rem 2rem;

    &.mobile {
      @media (min-width: $breakpoint-tablet) {
        display: none;
      }
    }
    &.desktop {
      display: none;
      @media (min-width: $breakpoint-tablet) {
        display: flex;
      }
    }

    @media (min-width: $breakpoint-tablet) {
      margin-top: 0.5rem;
    }

    .status-label {
      color: #ff4c00;
      font-family: var(--elliot-bold);
      font-size: 16px;
      text-transform: uppercase;
      margin-left: 1rem;
    }
  }
  &-wrapper {
    .section {
      &-title {
        padding: 1.5rem 0.5rem;
        @media (min-width: $breakpoint-tablet) {
          grid-row: 1/2;
          grid-column: 1/2;
        }
        .type {
          color: #0c2340;
          font-size: 12px;
          font-family: var(--elliot-bold);
          letter-spacing: 1px;
          line-height: 18px;
          text-transform: uppercase;
          @media (min-width: $breakpoint-tablet) {
            font-size: 14px;
            line-height: 20px;
          }
        }
        .title {
          color: #3c3c3c;
          font-size: 18px;
          font-family: var(--elliot-bold);
          letter-spacing: 0;
          line-height: 26px;
          @media (min-width: $breakpoint-tablet) {
            color: #353535;
            font-size: 22px;
            line-height: 30px;
          }
        }
        .org-units {
          display: flex;
          flex-direction: column;
          padding: 0.5rem 0;

          .unit {
            opacity: 0.5;
            color: #000000;
            font-size: 16px;
            letter-spacing: 0;
            line-height: 18px;

            .label {
              font-family: "FS Elliot Pro Bold";
              color: black;
            }

            .secondary-ou,
            .sub-industry {
              color: black;
              opacity: 0.8;
            }

            @media (min-width: $breakpoint-tablet) {
              color: #353535;
              font-size: 20px;
              line-height: 32px;
            }
          }
        }

        /* .sub-industry {
          display: flex;
          flex-wrap: wrap;
          padding-bottom: 1rem;
          color: #000000;

          @media (min-width: $breakpoint-tablet) {
            color: #353535;
            font-size: 20px;
            line-height: 32px;
            opacity: 0.5;
          }
        }*/
      }
      &-tags {
        display: flex;
        padding: 1rem 0;
        flex-wrap: wrap;

        @media (min-width: $breakpoint-tablet) {
          grid-column: 1/2;
        }
        .tag {
          margin: 0.5rem 0.5rem;
          font-size: 14px;
          color: #768292;
          @media (min-width: $breakpoint-tablet) {
            color: #7c899a;
            font-size: 18px;
            letter-spacing: 0;
            line-height: 18px;
          }
        }
      }
      &-epics {
        display: flex;
        padding: 1rem 0;
        align-items: center;
        flex-wrap: wrap;

        @media (min-width: $breakpoint-tablet) {
          grid-column: 1/2;
        }

        .epic {
          border-radius: 15px;
          border: 2px solid #e5e7ea;
          padding: 0.3rem 1rem;
          margin: 0.5rem 0.5rem;
          align-items: center;
          justify-content: center;
          display: block;
          color: #0c233f;
          font-size: 10px;
          font-weight: bold;
          letter-spacing: 0.83px;
          line-height: 10px;
          @media (min-width: $breakpoint-tablet) {
            font-size: 12px;
            font-weight: bold;
            letter-spacing: 1px;
            line-height: 10px;
          }
        }
      }
      &-references {
        background-color: #f7f8f9;
        margin: 0 -2rem;
        padding: 4rem 2rem;
        @media (min-width: $breakpoint-tablet) {
          margin: 0;
        }
        .title {
          color: #000000;
          font-size: 12px;
          font-weight: bold;
          letter-spacing: 1px;
          line-height: 18px;
        }
      }
    }

    @media (min-width: $breakpoint-tablet) {
      display: grid;
      grid-template-columns: 1fr 30%;
      padding-top: 2rem;
      padding-bottom: 6rem;
    }
  }
}

.legend-headbar {
  padding-top: 30px;
}

@media (max-width: 991px) {
  .legend-headbar {
    display: none;
  }
}
