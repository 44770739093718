@import "styles/_variables";

#body-div {
  p {
    color: black;
  }


  strong {
    font-family: "FS Elliot Pro Bold";
    color: black;
  }
}

.container-detail.grey {
  margin-left: -2rem;
  width: calc(100% + 4rem);
  padding: 0 2rem;
  background-color: #f7f8f9;
}

/* Qual Header*/

.qual-header {
  padding: 24px 0 32px;
  & .category-detail {
    font-family: var(--elliot-bold);
    font-size: 12px;
    color: #0c2340;
    letter-spacing: 1px;
    line-height: 18px;
    margin-bottom: 8px;
    margin-top: 1rem;
  }

  & .title {
    font-family: var(--elliot-regular);
    color: #0d243f;
    font-size: 32px;
    letter-spacing: 0;
    line-height: 32px;
    margin-bottom: 8px;
  }
  & .reactions-client {
    margin-bottom: 24px;
    & .reactions {
      & .likes {
        color: black;
      }
    }
    & .client {
      & span {
        color: black;
      }
    }
  }
  & .reactions {
    display: none;
  }
  & .client {
    font-family: var(--elliot-bold);
    color: #000000;
    opacity: 0.5;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 18px;
    margin-bottom: 24px;
    & span {
      font-family: var(--elliot-regular);
    }
    & a {
      font-family: var(--elliot-regular);
      color: #000000;
    }
  }
  & .chips {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    & > * {
      font-family: var(--elliot-bold);
      font-size: 10px;
      margin-right: 8px;
      margin-bottom: 8px;
      color: #0c233f;
      text-decoration: none;
      letter-spacing: 0.83px;
      line-height: 10px;
      border-radius: 12px;
      background-color: #0c233f18;
      padding: 7px 8px;
    }
  }
}

/* Qual Data */

.container-detail.qual-data-container {
  margin-left: -2rem;
  width: calc(100% + 4rem);
  padding: 0 2rem;
  background-color: #f7f8f9;
}

.reactions-container {
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 0.5px solid #24224240;
  margin-bottom: 24px;
  & .flag {
    height: 12px;
    width: 25px;
  }
}

.reactions {
  display: flex-start;
  align-items: center;
  font-family: var(--elliot-bold);
  font-size: 14px;
  letter-spacing: 0;
  line-height: 14px;
  & * {
    color: black;
  }
  & img {
    height: 15px;
    margin-right: 8px;
  }
  & .comments {
    font-family: var(--elliot-regular);
    color: #000000;
  }
}

/* Qual Info */

.qual-info {
  margin-bottom: 40px;
  & .title {
    font-family: var(--elliot-bold);
    font-size: 18px;
    letter-spacing: 0;
    line-height: 24px;
    color: #0c233f;
    margin-bottom: 6px;
  }
  & .tags {
    font-family: var(--elliot-regular);
    font-size: 14px;
    letter-spacing: 0;
    line-height: 24px;
    color: #768292;
    margin-bottom: 10px;
  }
  & .content {
    font-family: var(--elliot-regular);
    color: #353535;
    line-height: 24px;
  }
  & .content a {
    font-family: var(--elliot-bold);
    color: #353535;
  }
}

/* Qual Categories */

.qual-categories {
  margin-bottom: 32px;
  display: flex;
  flex-wrap: wrap;
  & > * {
    width: 50%;
    margin-bottom: 12px;
  }
  & .reference-category {
    display: none;
  }
  & .label {
    font-family: var(--elliot-bold);
    color: #000000;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
  }
  & .value {
    font-family: var(--elliot-regular);
    color: #0c2340;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
    > div {
      color: #0c2340;
    }
  }
}

/* Qual Stats */

.qual-stats {
  width: 90%;

  margin-bottom: 40px;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  color: #353535;
  & span {
    color: var(--black);
    margin-left: 0.5rem;
    font-family: var(--elliot-regular);
  }
  & div {
    color: var(--black);
    font-family: var(--elliot-bold);
  }
}

/* Qual Reference*/

.qual-reference {
  border-top: 0.5px solid #24224240;
  padding: 24px 0 27px;
  & .title {
    font-family: var(--elliot-bold);
    color: #000000;
    width: 100%;
    margin-bottom: 20px;
    letter-spacing: 1px;
    line-height: 18px;
    font-size: 12px;
  }
  & .reference {
    display: flex;
    align-items: flex-start;
    .avatar-container {
      margin-right: 1rem;
    }
  }
  & img {
    width: 46px;
    height: 46px;
    margin-right: 12px;
  }
  & .name {
    font-family: var(--elliot-bold);
    margin-bottom: 4px;
  }
  & .info {
    font-family: var(--elliot-regular);
    color: #000000;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 18px;
    & * {
      color: black;
    }
  }
}

/* CARD STYLE FROM OFFERINGS */

.offering-card {
  background: #f6f6f6;
  & .status {
    display: flex;
    align-items: center;
  }
  & .status-icon {
    width: 13px;
    height: 13px;
    //background: #ff4c00;
    margin-right: 6px;
  }
  & .status-label {
    font-family: var(--elliot-bold);
    color: #ff4c00;
    letter-spacing: 0.83px;
    line-height: 10px;
    font-size: 10px;
  }
}

/* CARD ADDED STYLE */

.qual-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  border: 0.5px solid #97979790;

  & .body {
    margin-bottom: 16px;
  }
  & .body:last-child {
    margin-bottom: 0;
  }

  & .status {
    display: flex;
    align-items: center;
  }
  & .status-icon {
    width: 13px;
    height: 13px;
    //background: #ff4c00;
    margin-right: 6px;
  }

  & .status-label-mock {
    font-family: var(--elliot-bold);
    color: #ff4c00;
    letter-spacing: 0.83px;
    line-height: 10px;
    font-size: 10px;
  }
}

/* BOTTOM REACTIONS */

.bottom-reactions {
  padding-top: 23px;
  margin-bottom: 46px;
  & .reaction-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  & .divider {
    margin: 6px 0 10px;
    height: 1px;
    background-color: #242242;
    opacity: 0.89;
  }
}

.reaction-line {
  & > .text {
    font-family: var(--elliot-regular);
    font-size: 16px;
    letter-spacing: 0;
    line-height: 32px;
  }
  & > .like-icon {
    height: 19px;
  }
  & .reactions {
    font-family: var(--elliot-bold);
    font-size: 14px;
    letter-spacing: 0;
    line-height: 14px;
    color: #000000;
    opacity: 0.52;
    & .comments {
      font-family: var(--elliot-regular);
    }
    & a {
      color: #000000;
    }
  }
  & .review {
    font-family: var(--elliot-regular);
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 14px;
    color: #0e4576;
  }
  & * {
    color: black;
  }
}

/* COMMENTS */

.comments-container {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  padding-bottom: 37px;
  & * {
    color: black;
  }
}

.comment {
  width: 100%;
  min-width: 100%;
  scroll-snap-align: start;
  margin-right: 32px;
  & .author {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 18px;
    color: #5c5c5c;
    font-family: var(--elliot-regular);
    margin-bottom: 25px;
  }
  & .author .propic {
    height: 36px;
    width: 36px;
    border-radius: 50%;
    background-color: grey;
    margin-right: 10px;
  }
  & .author .name {
    font-family: var(--elliot-bold);
    color: #000000;
    margin-bottom: 2px;
  }
  & .body {
    font-family: var(--elliot-regular);
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    color: #353535;
    & .title {
      font-family: var(--elliot-bold);
      margin-bottom: 8px;
    }
  }
}

/* GET IN TOUCH - QUAL FOOTER */
.qual-footer-detail {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  margin-left: -2rem;
  width: calc(100% + 4rem);
  padding: 0 2rem;
  background-color: #0d243f;
}

.contact {
  padding-top: 42px;
  color: #ffffff;
  font-family: var(--elliot-regular);
  margin-bottom: 50px;
  & .detail {
    font-family: var(--elliot-bold);
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 18px;
    margin-bottom: 23px;
  }
  & .title {
    font-size: 26px;
    letter-spacing: 0;
    line-height: 20px;
    margin-bottom: 16px;
  }
  & .info {
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
    margin-bottom: 20px;
  }
  & .link {
    position: relative;
    padding: 9px 11px;
    background-color: #d8d8d83f;
    border-radius: 4px;
    height: 2rem;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 22px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    & .copy-icon {
      position: absolute;
      right: 13px;
    }
    input {
      border: none;
      appearance: none;
      color: inherit;
      font-size: inherit;
      line-height: inherit;
      width: 100%;
      background-color: transparent;
    }
    svg {
      width: 2rem;
      height: 2rem;
      cursor: pointer;
      path {
        fill: white;
      }
    }
  }
}

.qual-reference.white {
  & * {
    color: #ffffff;
  }
}

.qual-reference.white img {
  width: 60px;
  height: 60px;
}

.qual-reference.white .title {
  border-bottom: 1px solid white;
  padding-bottom: 5px;
}

.qual-reference.white a {
  color: #ffffff;
}

/*  DESKTOP  */

@media (min-width: $breakpoint-tablet) {
  .container-detail.grey {
    margin-left: -4rem;
    width: calc(100% + 8rem);
    padding: 0 7rem;
    background-color: #f7f8f9;
  }

  .container-detail.qual-footer-detail {
    margin-left: -4rem;
    width: calc(100% + 8rem);
    padding: 5rem 7rem;
    flex-wrap: nowrap;
  }

  .header {
    height: 175px;
  }

  /*  QUAL HEADER  */

  .qual-header {
    padding: 48px 0 64px;

    & .title {
      font-size: 56px;
      line-height: 64px;
      max-width: 45%;
      margin-bottom: 15px;
      color: #353535;
    }
    & .reactions-client {
      display: flex;
    }
    & .reactions {
      display: block;
      margin-right: 56px;
      font-size: 20px;
      line-height: 32px;
    }
    & .client {
      font-size: 20px;
      line-height: 32px;
      margin-bottom: 0;
    }
    & .chips > * {
      font-size: 12px;
      margin-right: 10px;
      margin-bottom: 10px;
      padding: 11px 12px;
    }
  }

  /*  QUAL DATA  */

  .container-detail.qual-data-container {
    width: 100%;
    margin: 0;
    padding: 0;
    background-color: transparent;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    & .main-info {
      width: 60%;
    }
  }

  .qual-info {
    max-width: 75%;
    & .title {
      line-height: 32px;
      font-size: 24px;
      color: #353535;
      margin-bottom: 4px;
    }
    & .tags {
      color: #7c899a;
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 16px;
    }
    & .content {
      color: #353535;
      font-size: 18px;
      line-height: 28px;
    }
  }

  .qual-categories {
    max-width: 80%;
    & > * {
      width: 25%;
      padding-right: 0.5em;
    }
    & .reference-category {
      display: block;
    }
    & .label,
    & .value {
      font-size: 18px;
      line-height: 28px;
    }
  }

  .side-info {
    right: 0;
    top: 0;
    width: 40%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    & .qual-reference {
      display: none;
      &.tech-reference {
        display: block;
      }
    }
    & > * {
      box-sizing: border-box;
      padding: 40px;
      background-color: #f7f8f9;
      width: 100%;
      margin: 0;
    }
  }

  /* QUAL STATS */

  .qual-stats {
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 48px;
  }

  .qual-reference {
    border: none;
    & .title {
      font-size: 14px;
      letter-spacing: 1.17px;
      line-height: 18px;
    }
    & img {
      width: 64px;
      height: 64px;
      margin-right: 24px;
    }
    & .info {
      font-size: 18px;
      line-height: 26px;
    }
  }

  /* BOTTOM REACTIONS */

  .bottom-reactions {
    position: relative;
    padding-top: 30px;
    margin-bottom: 57px;
    & .divider {
      margin: 31px 0 14px;
      height: 2px;
      background-color: #979797;
    }
    & .reaction-line:first-child {
      justify-content: flex-start;
    }
  }

  .reaction-line {
    & .text {
      margin-right: 50px;
      font-size: 24px;
      line-height: 32px;
      color: black;
    }
    & .like-icon {
      height: 29px;
    }
    & .reactions {
      font-size: 18px;
      line-height: 32px;
    }
    & .reactions img {
      display: none;
    }
    & .review {
      position: absolute;
      top: 30px;
      right: 0;
      line-height: 32px;
      font-size: 16px;
    }
  }

  .reactions-container {
    display: none;
  }

  /* COMMENTS */

  .comments-container {
    overflow-x: hidden;
    flex-wrap: wrap;
  }

  .comment {
    min-width: initial;
    width: 40%;
    margin-bottom: 40px;
    &:nth-of-type(2n-1) {
      margin-right: 20%;
    }
    &:nth-of-type(2n) {
      margin-right: 0;
    }
    & .author {
      font-size: 16px;
      line-height: 24px;
      color: #777777;
      & .propic {
        height: 53px;
        width: 53px;
      }
    }
    & .body {
      font-size: 18px;
    }
  }

  .qual-footer-detail .contact {
    width: 40%;
    margin-right: 20%;
    padding-top: 0;
    margin-bottom: 0;
  }

  .contact {
    & .detail {
      font-size: 16px;
      letter-spacing: 1.33px;
      line-height: 18px;
      margin-bottom: 32px;
    }
    & .title {
      font-size: 48px;
      line-height: 1;
      margin-bottom: 36px;
    }
    & .info {
      font-size: 20px;
      line-height: 28px;
    }
    & .link {
      width: 70%;
    }
  }

  .qual-footer-detail .qual-reference {
    width: 40%;
    padding-top: 22px;
  }
}
