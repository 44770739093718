@import "styles/_variables";

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 200;
}

.modal-main {
  position: fixed;
  background: white;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: scroll;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.bo-link {
  color: #3c4e65;
  text-transform: uppercase;
  text-decoration: underline;
  font-family: var(--elliot-bold);
  background: none;
  cursor: pointer;
  border: none;
  outline: none;
  &:disabled {
    opacity: 0.2;
  }
}

.bo-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  max-width: 100%;
  padding: 12px;
  height: 40px;
  background-color: #3c4e65;
  border-radius: 32px;
  text-transform: uppercase;
  cursor: pointer;
  border: none;
  outline: none;
  text-decoration: none;
  &.bo-button-light {
    background-color: transparent;
    color: #3c4e65;
    border: 2px solid #3c4e65;
  }
  &:last-child {
    margin-bottom: 48px;
  }
  &:disabled {
    opacity: 0.2;
  }
}

.bo-header {
  position: relative;
  border-bottom: 1px solid #0002;
  margin-bottom: 80px;
  padding-bottom: 27px;
  & .bo-title {
    font-size: 40px;
    color: #353535;
    font-family: var(--elliot-regular);
    margin: 0;
    line-height: 64px;
  }
  & .bo-title-value {
    font-size: 40px;
    color: #353535;
    font-family: var(--elliot-bold);
    margin: 0;
    line-height: 64px;
  }
  & .bo-header-close {
    cursor: pointer;
    position: absolute;
    top: 2rem;
    right: 2rem;
    & img {
      height: 20px;
    }
  }
}

.bo-container {
  margin-bottom: 121px;
  position: relative;
  & .bo-form-container {
    background-color: #f7f8f9;
    padding-bottom: 1px;
    margin-bottom: 48px;
    & .bo-form-section-title {
      padding: 12px 32px;
      background-color: #112849;
      font-family: var(--elliot-bold);
      font-size: 20px;
    }
    & .bo-form-section {
      padding: 30px 20px 0;
    }
    & .bo-form-subtitle {
      font-family: var(--elliot-regular);
      color: #353535;
      font-size: 28px;
      margin-bottom: 36px;
    }
    & .bo-form-input-group {
      margin-bottom: 48px;
    }
    & .bo-button-list {
      display: flex;
      margin-bottom: 32px;
      > * {
        margin-right: 16px;
      }
    }
    & .bo-achievement-delete-container {
      margin-bottom: 34px;
      display: flex;
      justify-content: flex-end;
      & .delete-icon {
        margin-left: auto;
        cursor: pointer;
        height: 19px;
        width: 19px;
      }
    }
    & .bo-form-divider {
      height: 1px;
      margin-bottom: 16px;
      background-color: #979797;
    }
    & .bo-text-hint {
      width: 100%;
      color: #666;
      font-size: 16px;
      line-height: 22px;
    }
    & .bo-form-input-container {
      margin-bottom: 20px;
      & label {
        display: flex;
        color: black;
        font-family: var(--elliot-bold);
        margin-bottom: 10px;
        text-transform: uppercase;
        & span {
          margin-left: 4px;
          color: red;
          &.bo-input-counter {
            display: inline-block;
            font-family: var(--elliot-regular);
            margin-left: auto;
            font-size: 12px;
            color: black;
          }
        }
        & img {
          margin-left: 4px;
          height: 12px;
        }
        &.file-label {
          cursor: pointer;
          text-transform: none;
          font-family: var(--elliot-regular);
          font-size: 14px;
          color: #666666;
          display: flex;
          align-items: center;
          width: 100%;
          border: 0.5px solid #8c8e90;
          outline: none;
          height: 50px;
          padding-left: 15px;
          border-radius: 4px;
          + input[type="file"] {
            display: none;
          }
        }
      }
      .error-msg {
        color: red;
        margin-top: 10px;
        &::before {
          display: inline;
          content: "⚠ ";
        }
      }
      & p {
        margin-top: 10px;
        color: red;
      }
      & p::before {
        display: inline;
        content: "⚠ ";
      }

      & > input,
      > .bo-form-input-line > input {
        border: 0.5px solid #8c8e90;
        outline: none;
        min-height: 50px;
        padding-left: 15px;
        border-radius: 4px;
        width: 100%;
        + .bo-form-chip-list {
          padding-top: 0.5rem;
        }
        &.bo-form-short-input {
          width: 45%;
          margin-right: 24px;
        }
        + .reference-search {
          color: black;
          height: 50px;
        }
      }
      & .bo-form-input-line {
        display: flex;
        justify-content: space-between;
        & .custom-select-wrapper {
          flex-grow: 1;
        }
      }
      & textarea {
        border: 0.5px solid #8c8e90;
        outline: none;
        padding: 10px;
        color: #666666;
        resize: none;
        border-radius: 4px;
        width: 100%;
      }
      & .bo-form-code {
        padding-top: 10px;
        & .bo-form-code-label {
          color: #353535;
          font-family: var(--elliot-regular);
        }
        & .bo-form-code-value {
          color: black;
          font-family: var(--elliot-bold);
          letter-spacing: 2px;
          font-size: 20px;
        }
      }
      & .custom-select-wrapper {
        background-color: white;
        border-radius: 4px 4px 0 0;
        border: 0.5px solid #8c8e90;
        height: 50px;
        margin-bottom: 15px;
        & .custom-select-input {
          border: none;
          margin: auto 0;
          padding-left: 11px;
          align-items: center;
          & .selected {
            display: none;
          }
        }
      }
      & .bo-form-toggle-input {
        display: flex;
        align-items: center;
        & .toggle {
          position: relative;
          height: 30px;
          width: 60px;
          border-radius: 20px;
          background-color: #d6d6d6;
          transition: background-color 0.2s;
          margin-right: 12px;
          & .handle {
            position: absolute;
            top: 3px;
            left: 3px;
            background-color: white;
            height: 24px;
            width: 24px;
            border-radius: 16px;
            transition: left 0.2s;
          }
          &.active {
            background-color: #0d243f;
            & .handle {
              left: 33px;
            }
          }
        }
        & .text-value {
          font-size: 14px;
          color: black;
          font-family: var(--elliot-regular);
        }
      }
    }
    & .bo-form-chip-list {
      display: flex;
      flex-wrap: wrap;
      padding-top: 0.5rem;
      & .bo-form-chip {
        display: flex;
        align-items: center;
        font-family: var(--elliot-bold);
        color: #0d243f;
        margin-right: 16px;
        margin-bottom: 8px;
        background-color: #dfe3e6;
        padding: 9px;
        border-radius: 16px;
        & .chip-icon {
          cursor: pointer;
          height: 8px;
          margin-left: 12px;
        }
      }
    }
  }
  .legend {
    position: absolute;
    top: 0;
    right: 0;
    width: 15rem;
    height: 5rem;

    div {
      margin: 0.5rem 0;
    }
    label {
      color: #000;
      span {
        margin-right: 4px;
        color: red;
      }
    }

    .issue-alert {
      margin-right: 10px;
      margin-top: 10px;
      padding: 20px 14px 20px 20px;
      border-radius: 10px;
      ul {
        padding-top: 10px;
        padding-left: 10px;
        padding-bottom: 5px;
      }
      li {
        color: #000;
        padding-bottom: 5px;
      }
      a {
        color: #000;
      }

      &.issue-alert-with-errors {
        color: #ff0000;
        background-color: rgba(255, 0, 0, 0.09);
      }

      &.issue-alert-with-alerts {
        color: #3c3c3c;
        background-color: rgba(60, 60, 60, 0.09);
      }

      &.issue-alert-with-info {
        color: #2ab673;
        background-color: rgba(42, 182, 115, 0.09);
      }
    }
  }
  & .bo-submit-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    & .bo-link {
      margin-bottom: 32px;
    }
    & .bo-button {
      width: 100%;
      max-width: 232px;
    }
  }
}

@media (min-width: $breakpoint-tablet) {
  .bo-header {
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 1rem;
  }
  .bo-container {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 824px 1fr;
    & .bo-form-container {
      grid-column: 2;
      padding-bottom: 0;
      margin-bottom: 0;
      & .bo-form-section-title {
        font-size: 16px;
      }
      & .bo-form-divider {
        width: 364px;
      }
      & .bo-text-hint {
        width: 341px;
        &.large {
          width: 100%;
          margin-bottom: 31px;
        }
      }
      & .bo-form-section {
        padding: 40px 32px 0;
      }
      & .bo-form-subtitle {
        font-size: 24px;
      }
      & .bo-form-input-group {
        margin-bottom: 72px;
      }
      & .bo-form-chip-list {
        & .bo-form-chip {
          height: 30px;
          border-radius: 17.5px;
          font-size: 12px;
          padding: 12px;
          letter-spacing: 1px;
          text-transform: uppercase;
        }
      }
      & .bo-achievement-delete-container {
        width: 364px;
      }
      & .bo-form-input-container {
        position: relative;
        margin-bottom: 32px;
        & label {
          font-size: 12px;
          margin-bottom: 15px;
          width: 364px;
          &.bo-large-label {
            width: 628px;
          }
          &.file-label {
            width: 364px;
            height: 40px;
            border: 0.5px solid #8c8e90;
            outline: none;
            padding-left: 15px;
            border-radius: 4px;
          }
        }
        & .custom-select-wrapper {
          width: 364px;
          height: 40px;
          margin-bottom: 0.5rem;
          & .custom-select-input {
            height: 100%;
            & .label {
              font-size: 14px;
              color: #666666;
            }
          }
          & .custom-select-options {
            margin: 0 calc(-0.3rem - 1px);
            z-index: 100;
            border: 0.5px solid #8c8e90;
            padding: 0;
            border-radius: 0 0 4px 4px;
            & .custom-select-option {
              padding: 0.8rem 1rem;
              &:last-child {
                border: none;
              }
            }
          }
        }
        & > input,
        > .bo-form-input-line > input {
          font-size: 14px;
          width: 364px;
          padding-left: 16px;
          color: #666666;
          min-height: 40px;
          &:read-only {
            background-color: #dfdfdf;
            border: none;
          }
          &.bo-form-short-input {
            margin-right: 32px;
            width: 100px;
          }
          + .bo-button {
            margin-left: 8px;
            display: initial;
          }
        }
        & .bo-form-input-line {
          width: 364px;
        }
        & textarea {
          font-size: 14px;
          width: 628px;
          padding: 16px;
          &.bo-form-short-textarea {
            width: 364px;
          }
        }
        & .bo-form-code {
          padding: 0;
          position: absolute;
          left: 415px;
          bottom: 0;
          & .bo-form-code-label {
            font-size: 16px;
            margin-bottom: 11px;
          }
          & .bo-form-code-value {
            font-size: 24px;
          }
        }
      }
    }
    & .bo-submit-container {
      margin-top: auto;
    }

    .input-error {
      label {
        &::after {
          content: url("../../images/exclamation.svg");
          left: 370px;
          top: 32px;
          position: absolute;
        }
      }
      input {
        border: 1px solid #ff0000 !important;
      }
      .custom-select-wrapper {
        border: 1px solid #ff0000 !important;

        input {
          border: transparent !important;
        }
      }
      .input-with-icon__container {
        border: 1px solid #ff0000 !important;

        input {
          border: transparent !important;
        }
      }
      &#handle-issues-body {
        label {
          &::after {
            content: "";
          }
        }
      }
      .ck-editor {
        border: 1px solid #ff0000 !important;
        border-radius: 5px;
      }
      .bo-form-code-value {
        color: #ff0000 !important;

        &::after {
          content: url("../../images/exclamation.svg");
          left: 173px;
          top: 26px;
          position: absolute;
        }
      }

      .file-label {
        border: 1px solid #ff0000 !important;
      }
    }
  }
}

.CKEditor-container {
  color: black !important;

  span {
    color: black !important;
  }

  p {
    color: black !important;
  }

  strong {
    color: black !important;
  }

  i {
    color: black !important;
  }

  u {
    color: black !important;
  }

  p::before {
    content: none !important;
  }

  li {
    color: black !important;
  }

  label {
    display: none !important;
  }

  .ck-editor__editable {
    padding-left: 20px !important;
  }

  .text-big {
    font-size: 1.25rem;
  }

  .text-tiny {
    font-size: 0.85rem;
  }

  .ck-fontsize-option {
    font-size: 1rem;
  }

  .ck.ck-editor__editable > .ck-placeholder::before {
    color: #d21714;
  }

  li::marker {
    padding-left: 10px;
    color: black;
    //font-size: 20px;
    margin-left: 20px;
  }
}

.no-textarea {
  display: none;
}
