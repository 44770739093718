.circle-button {
  position: fixed;
  bottom: 21px;
  right: 30px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #0d243f;
  color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

// Card Legend
.card-container {
  position: fixed;
  bottom: 21px;
  right: 30px;
  width: 420px;
  height: 228px;
  color: #000;
  background: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-bottom: 20px;
}

@media (max-width: 744px) {
  .card-container {
    width: 100%;
    left: 0;
    bottom: 0;
    margin-bottom: 0;
  }
}

.card-header {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}

.close-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
  color: #ccc;
  padding-right: 9px;
  padding-top: 2px;
}

.close-legend-button {
  filter: invert(16%) sepia(97%) saturate(4586%) hue-rotate(333deg)
    brightness(85%) contrast(112%);
  transform: scale(1.5);
}

.close-button:hover {
  color: #000;
}

.card-body {
  padding: 0px 25px;
}

// Legend content

.flex-container {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  align-items: center;
  margin-bottom: 18px;
}

.color-box {
  width: 33px;
  height: 12px;
  margin-right: 17px;
}
