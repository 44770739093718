@import "styles/_variables";
@import "styles/_mixin";

.type {
  font-size: 12px;
  line-height: 18px;
  font-family: var(--elliot-bold);
  text-transform: uppercase;
}

.heading_one {
  font-size: 32px;
  line-height: 38.4px;
}

.heading_two {
  font-size: 24px;
  line-height: 28.8px;
}

.regular {
  font-size: 16px;
  line-height: 24px;
}

.bold {
  font-family: var(--elliot-bold);
}

.small {
  font-size: 12px;
  line-height: 18px;
}

.link-underlined {
  font-family: var(--elliot-bold);
  font-size: 16px;
  line-height: 24px;
}

@media (min-width: $breakpoint-tablet) {
  .heading_one {
    font-size: 56px;
    line-height: 64px;
  }

  .heading_two {
    font-size: 27px;
    line-height: 40.5px;
  }

  .regular {
    font-size: 18px;
    line-height: 28px;
  }

  .small {
    font-size: 14px;
    line-height: 20px;
  }

  .link-underlined {
    font-size: 18px;
    line-height: 28px;
  }
}
