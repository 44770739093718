@import "styles/_variables";

.content-request {
  .modal {
    background: transparent;
    top: 100px;

    @media (min-width: $breakpoint-tablet) {
      top: 30px;
      position: relative;
      z-index: 10;
    }

    .modal-main {
      top: 100px;

      @media (min-width: $breakpoint-tablet) {
        position: relative;
        top: 180px;
      }
    }
  }

  p {
    color: black!important;
    padding-bottom: 20px;
    font-size: 20px;
    width: 80%;
    line-height: 30px;
  }

  .disabled {
    color: #666666;
    border: 0!important;
    background-color: #DFDFDF;
  }

  .filter-number {
    display: none;
  }

}

