@import "styles/_variables";

.select-container {
  & .label {
    color: #050505;
    font-family: var(--elliot-regular);
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 14px;
  }

  & select {
    font-family: var(--elliot-regular);
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 14px;
    border: none;
    background: none;
    color: #0e4576;
    text-decoration: underline;
  }
}

.search-container {
  position: relative;
  height: 48px;
  & .search-bar {
    font-family: var(--elliot-regular);
    box-sizing: border-box;
    width: 100%;
    line-height: 2;
    border: 0.5px solid #979797;
    border-radius: 4px;
    padding: 6px 40px 9px;
    font-size: 16px;
    color: var(--black);
    &::placeholder {
      line-height: 2;
    }
  }
  & .search-icon {
    position: absolute;
    width: 16px;
    height: 16px;
    top: 50%;
    left: 18px;
    transform: translateY(-50%);
  }
}

.view-bar-serp {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  & .results-count {
    display: none;
    & .results-label {
      color: var(--black);
    }
  }
}

.view-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  & .results-count {
    display: none;
    & .results-label {
      color: var(--black);
    }
  }
}

.view-options-container {
  height: 28px;
  display: flex;
  align-items: center;

  & .label {
    font-family: var(--elliot-regular);
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 14px;
    color: #050505;
    margin-right: 8px;
  }

  & .view-option {
    font-family: var(--elliot-bold);
    font-size: 10px;
    letter-spacing: 0.83px;
    line-height: 10px;
    padding: 9px 12px;
    border: 0.5px solid #0d243f80;
    margin-right: 12px;
    border-radius: 2px;
    text-decoration: none;
    color: #0c233f;
  }
}

.view-option.selected {
  color: white;
  background: #0c233f;
}

.view-bar .view-option:last-child {
  margin-right: 0;
}

.qual-results-title-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  .qual-results-title {
    font-size: 16px;
    color: #050505;
    font-family: var(--elliot-bold);
    letter-spacing: 1px;
    line-height: 14px;
  }
  a {
    color: #0e4576;
    font-family: var(--elliot-regular);
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 14px;
  }
}

.results-cards {
  display: flex;
  justify-content: flex-start;
  overflow-x: scroll;
  margin-bottom: 48px;
  > span {
    display: block;
    height: 216px;
    overflow: hidden;
    width: 100%;
    margin: 1rem 0.2rem;
    span.react-loading-skeleton {
      width: 100% !important;
    }
  }
  scrollbar-face-color: #3c4e65;
  &.scroll-down {
    min-width: 100%;
    max-width: 100%;
    padding: 0;
    margin: 0;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-bottom: 54px;
    overflow: inherit;
    padding-top: 2rem;
  }
  .result-card {
    margin: 1rem 0.2rem;
  }
}

.results-cards-contributor {
  display: flex;
  justify-content: flex-start;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #f7f8f9;
    border-radius: 4px;
    border: 1px solid #3c4e65;
  }

  &::-webkit-scrollbar-thumb {
    background: #3c4e65;
    border-radius: 4px;
  }
  height: 840px;
  margin-bottom: 48px;
  > span {
    display: block;
    height: 216px;
    //overflow: hidden;
    width: 100%;
    margin: 1rem 0.2rem;
    span.react-loading-skeleton {
      width: 100% !important;
    }
  }
  scrollbar-face-color: #3c4e65;
  &.scroll-down {
    min-width: 100%;
    max-width: 100%;
    padding: 0;
    margin: 0;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-bottom: 54px;
    //overflow: hidden;

    //scrollbar-face-color: #3C4E65;
  }
  .result-card {
    margin: 1rem 0.2rem;
  }
}

/* Desktop Min Width 900 */

@media (min-width: $breakpoint-tablet) {
  .search-container .search-bar {
    padding: 9px 40px 13px;
  }

  .view-bar-serp {
    margin-bottom: 54px;

    & .results-count {
      display: block;
      font-family: var(--elliot-regular);
      opacity: 0.61;
    }
  }

  .view-bar {
    margin-bottom: 54px;
    justify-content: flex-end;

    & .results-count {
      display: block;
      font-family: var(--elliot-regular);
      opacity: 0.61;
    }
  }
  .results-cards {
    min-width: 100%;
    max-width: 100%;
    padding: 0;
    margin: 0;
    flex-wrap: wrap;
    margin-bottom: 54px;
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(2, 360px);
    column-gap: 1rem;
    row-gap: 2rem;
    justify-content: center;
    .result-card,
    > span {
      margin: 0;
    }
    > span {
      width: initial;
    }
  }
  .results-cards-contributor {
    min-width: 100%;
    max-width: 100%;
    padding: 0;
    margin: 0;
    flex-wrap: wrap;
    margin-bottom: 54px;
    display: flex;
    justify-content: center;
    .result-card,
    > span {
      margin: 0;
    }
    > span {
      width: initial;
    }
  }
}

@media (min-width: 1480px) {
  .results-cards {
    grid-template-columns: repeat(3, 360px);
  }
}
@media (min-width: 1850px) {
  .results-cards {
    grid-template-columns: repeat(4, 360px);
  }
}

.load-more {
  padding: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  button {
    border-radius: 12px;
    border: 1px solid var(--main-bg);
    background-color: transparent;
    color: var(--main-bg);
    opacity: 0.6;
    padding: 1rem 1.5rem;
    cursor: pointer;
  }
}
