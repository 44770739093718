@import "styles/_variables";

.errors-box {
  width: 100%;

  .description-container {
    display: flex;
    padding-top: 10px;
  }

  .description {
    color: #353535;
    font-size: 12px;
    font-family: var(--elliot-regular);

    &.text {
      flex: 1;
    }
    &.arrow {
      padding: 0 10px;
    }
    &.solution {
      flex: 2;
    }
  }

  .errors-box-low {
    padding: 20px;
    border-radius: 10px;
    margin: 10px 0;
    background-color: rgba(42, 182, 115, 0.09);

    .title {
      font-family: var(--elliot-bold);
      color: #2AB673;
    }
  }

  .errors-box-medium {
    padding: 20px;
    margin: 10px 0;
    border-radius: 10px;
    color: #3C3C3C;
    background-color: rgba(60, 60, 60, 0.09);

    .title {
      font-family: var(--elliot-bold);
      color: #3C3C3C;
    }
  }

  .errors-box-high {
    padding: 20px;
    margin: 10px 0;
    border-radius: 10px;
    color: #FF0000;
    background-color: rgba(255, 0, 0, 0.09);

    .title {
      font-family: var(--elliot-bold);
      color: #FF0000;
    }
  }
}
