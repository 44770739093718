@import "styles/_variables";

.bo-container {
    //margin-bottom: 121px;
    & .bo-steps-container {
        & .bo-steps-title {
            color: black;
            font-family: var(--elliot-bold);
            font-size: 16px;
            letter-spacing: 1.33px;
            margin-bottom: 40px;
        }
        & .bo-step {
            display: flex;
            align-items: center;
            position: relative;
            margin-bottom: 40px;
            & .bo-step-number {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 32px;
                min-width: 32px;
                height: 32px;
                min-height: 32px;
                border-radius: 50%;
                border: 1px solid #cecfd1;
                color: #cecfd1;
                margin-right: 12px;
                font-size: 18px;
                font-family: var(--elliot-regular);
            }
            & .bo-step-name {
                color: #cecfd1;
                font-size: 18px;
                font-family: var(--elliot-regular);
            }
            &::after {
                content: "";
                position: absolute;
                bottom: 100%;
                height: 40px;
                left: 15px;
                width: 2px;
                background-color: #cecfd1;
            }
            &.bo-step-first {
                &::after {
                    display: none;
                }
            }
            &.bo-step-completed {
                & .bo-step-number {
                    border-color: #0c2340;
                    color: #0c2340;
                }
                & .bo-step-name {
                    color: #0c2340;
                }
                &::after {
                    background-color: #0c2340;
                }
            }
            &.bo-step-active {
                & .bo-step-number {
                    background-color: #0c2340;
                    color: white;
                    border: none;
                }
                & .bo-step-name {
                    color: #0c2340;
                    font-family: var(--elliot-bold);
                }
                &::after {
                    background-color: #0c2340;
                }
            }
        }
    }
}

@media (min-width: $breakpoint-tablet) {
    .bo-container {
        display: grid;
        width: 100%;
        grid-template-columns: 1fr 824px 1fr;
        & .bo-steps-container {
            top: 4rem;
            position: -webkit-sticky;
            position: sticky;
            margin: 0 auto auto auto;
        }
    }
}
