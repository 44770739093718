@import "styles/_variables";

.avatar-container {
  width: 80px;
  height: 80px;
  background-color: gray;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #959595;

  .avatar-img {
    max-height: 80px;
    max-width: 80px;
    height: auto;
    width: auto;
    overflow: hidden;
    border-radius: 50%;
  }

  @media (min-width: $breakpoint-tablet) {
    width: 50px;
    height: 50px;
  }
}
.avatar-palceholder {
  font-size: 1.2rem;
}
