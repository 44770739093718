.contact-via-teams-container {
  display: flex;
  flex-direction: row;
  height: 25px;
  align-items: center;
 .teams-image {
   height: 25px !important;
   width: 25px !important;
   margin-right: 10px;
 }
}
