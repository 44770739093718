@import "styles/_variables";

.sticker-fixed {
  position: fixed;
  bottom: 16px;
  left: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  .sticker-fixed__img {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    background: #FFFFFF;
    border: 0.7px solid #0D243F;
    z-index: 1;
    cursor: pointer;
  }
  .sticker-fixed__text {
    background-color: #FFFFFF;
    color: #0C2340;
    padding: 11px 11px 11px 32px;
    border: 1px solid #0D243F;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    opacity: 0;
    margin-left: -20px;
    transition: all 0.3s;
    display: flex;
    align-items: center;
  }
  .sticker-fixed__link {
    color: #0C2340;
    font-family: var(--elliot-bold);
    text-decoration: none;
  }
  .sticker-fixed__cross {
    margin-left: 16px;
    cursor: pointer;
  }
  &.sticker-fixed--open {
    .sticker-fixed__text {
      opacity: 1;
    }
  }
  @media (min-width: $breakpoint-tablet) {
    bottom: 40px;
    left: 40px;
    .sticker-fixed__img {
      width: 60px;
      height: 60px;
      border-radius: 60px;
    }
    .sticker-fixed__text {
      margin-left: -30px;
      padding: 20px 21px 20px 53px;
      font-size: 14px;
      line-height: 20px;
    }
    .sticker-fixed__cross {
      margin-left: 31px;
    }
  }
}
