@import "styles/_variables";
@import "styles/_mixin";

.maintenance {
  .home-container {
    position: absolute;
    top: 0;
    width: 100%;
    padding: 0 2rem;
    min-height: 100vh;
    @include background-image-blue;

    & .content {
      padding-top: 6rem;
    }
  }

  .opening {
    padding-top: 24px;
    padding-bottom: 62px;
    position: relative;

    .container {
      position: relative;
      width: auto;
      padding: 0;
    }

    & .logo {
      display: flex;
      justify-content: center;
      img {
        width: 20rem;
        height: 4rem;
      }
    }

    & .subtitle {
      font-family: var(--elliot-light);
      text-align: center;
      font-weight: 300;
      opacity: 0.69;
      margin-bottom: 32px;
      padding-top: 2rem;
    }
  }
}
