@import "styles/_variables";

.result-card {
  padding: 15px 18px;
  position: relative;
  text-decoration: none;
  cursor: pointer;

  .likes-bookmarks-icons-container {
    display: flex;
    align-items: center;
  }

  .card-likes-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  & .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
  }

  & .rating {
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  & .rating-icon {
    width: 15px;
    height: 15px;
    background: #3c3c3c;
    margin-right: 4px;
  }

  & .rating-value {
    font-family: var(--elliot-bold);
    color: var(--black);
    letter-spacing: 0.83px;
    line-height: 10px;
    font-size: 10px;
  }

  & .name {
    font-family: var(--elliot-regular);
    color: var(--black);
    font-size: 20px;
    letter-spacing: 0;
    line-height: 26px;
    margin-bottom: 16px;
  }

  & .body {
    display: flex;
  }

  & .body > * {
    margin-right: 4px;
    color: #818181;
    font-family: var(--elliot-bold);
    font-size: 14px;
    letter-spacing: 0;
    line-height: 14px;
  }

  & .body .year {
    font-family: var(--elliot-regular);
  }
}

.container.contributor .offering-card {
  height: auto;
}

.container.contributor .offering-card-contributor {
  height: auto;
}

.offering-card-contributor {
  background: #f6f6f6;
  min-height: 120px;
  display: grid;
  grid-template-rows: 1.5rem 1fr 2rem;
  width: 100%;
  margin-bottom: 20px;
  padding: 10px;

  & .status-banner {
    display: flex;
    flex-direction: row;
    justify-content: end;
    width: 100%;

    & .status {
      display: flex;
      align-items: center;
      margin-right: 7px;
    }

    & .add-bookmark {
      text-align: right;
    }
  }

  & .status-banner-modal {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 5px 0;

    & .status {
      display: flex;
      align-items: center;
      margin-right: 7px;
    }
    & .add-bookmark {
      margin-right: 5px;
    }

    & .likes-container {
      padding-bottom: 5px;
    }
  }

  & .status-container {
    display: flex;
    align-items: center;
  }

  & .status-icon {
    width: 13px;
    height: 13px;
    margin-right: 6px;
  }

  & .status-icon-institutional {
    width: 13px;
    height: 13px;
    margin-right: 3px;
    //margin-left: 7px;
  }

  & .status-label {
    font-family: var(--elliot-bold);
    color: #ff4c00;
    letter-spacing: 0.83px;
    line-height: 10px;
    font-size: 10px;
  }

  .name {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 0;
  }

  .body {
    grid-row: 3/-1;
  }

  & .draft-footer {
    @media (max-width: $breakpoint-tablet) {
      display: none;
    }

    background-color: #f7f8f9;

    //margin: 0 -17px -14px;
    //padding: 8px 18px;

    display: flex;
  }
}

.offering-card {
  background: #f6f6f6;
  min-height: 230px;
  display: grid;
  grid-template-rows: 1.5rem 1fr 2rem;
  width: 100%;
  height: 100%;

  & .status-banner {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    & .status-container {
      display: flex;
      align-items: center;
    }

    & .status {
      display: flex;
      align-items: center;
      margin-right: 7px;
    }

    & .add-bookmark {
      text-align: right;
    }
  }

  & .status-icon {
    width: 13px;
    height: 13px;
    margin-right: 6px;
  }

  & .status-icon-institutional {
    width: 13px;
    height: 13px;
    margin-right: 3px;
    //margin-left: 7px;
  }

  & .status-label {
    font-family: var(--elliot-bold);
    color: #ff4c00;
    letter-spacing: 0.83px;
    line-height: 10px;
    font-size: 10px;
  }
  .name {
    grid-row: 2/3;
  }
  .body {
    grid-row: 3/-1;
    .manager {
      .persona {
        color: #000;
        opacity: 0.4;
        margin-left: 0.3rem;
      }
    }
  }

  & .draft-footer {
    @media (max-width: $breakpoint-tablet) {
      display: none;
    }

    background-color: #f7f8f9;
    margin: 0 -17px -14px;
    padding: 8px 18px;

    display: grid;
    grid-template-columns: 30% 40% 30%;
  }
}

.qual-card {
  // display: block !important;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  border: 0.5px solid #97979790;
  width: 100%;
  height: 100%;

  &.qual-draft {
    cursor: initial;
    pointer-events: none;
  }

  & .industry {
    font-family: var(--elliot-bold);
    letter-spacing: 1px;
    font-size: 12px;
  }

  & .body {
    margin-bottom: 16px;
  }

  & .qual-footer {
    background-color: #f7f8f9;
    margin: 0 -17px -14px;
    padding: 8px 18px;

    display: grid;
    grid-template-columns: 35% 1fr 20%;

    &.draft-footer {
      @media (max-width: $breakpoint-tablet) {
        display: none;
      }
      grid-template-columns: 30% 40% 30%;
    }
  }
  & .card-header {
    & .status-banner {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;

      & .status {
        display: flex;
        align-items: center;
        margin-right: 7px;
      }

      & .add-bookmark {
        text-align: right;
      }
    }
    & .status-label {
      color: #007dd5;
      font-family: var(--elliot-bold);
      letter-spacing: 1px;
      font-size: 12px;
    }
  }
}
.qual-card-contributor {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  border: 0.5px solid #97979790;
  width: 100%;
  min-height: 120px;

  @media (min-width: $breakpoint-tablet) {
    width: 360px;
  }

  &.qual-draft {
    cursor: initial;
    pointer-events: none;
  }

  & .industry {
    font-family: var(--elliot-bold);
    letter-spacing: 1px;
    font-size: 12px;
  }

  & .body {
    margin-bottom: 16px;
  }

  & .qual-footer {
    background-color: #f7f8f9;
    margin: 0 -17px -14px;
    padding: 8px 18px;

    display: grid;
    grid-template-columns: 35% 1fr 20%;

    &.draft-footer {
      @media (max-width: $breakpoint-tablet) {
        display: none;
      }
      grid-template-columns: 30% 40% 30%;
    }
  }

  & .card-header {
    & .status-banner {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;

      & .status {
        display: flex;
        align-items: center;
        margin-right: 7px;
      }

      & .add-bookmark {
        text-align: right;
      }
    }

    & .status-label {
      color: #007dd5;
      font-family: var(--elliot-bold);
      letter-spacing: 1px;
      font-size: 12px;
    }
  }
}

.qual-footer {
  & .info {
    margin-right: 20px;
    min-width: 80px;
    margin-bottom: 8px;
    margin-top: 8px;
    max-width: 100%;
    &.flags {
      display: flex;
      flex-direction: column;
      align-items: center;

      .label {
        text-align: center;
        width: 100%;
      }
      .value {
        span {
          margin-right: 2px;
          img {
            width: 16px;
          }
        }
      }

      margin-right: 0;
      min-width: fit-content;
      margin-left: auto;
    }

    &.icons {
      margin-right: 0;
      min-width: initial;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      & > * {
        pointer-events: initial;
        cursor: pointer;
        margin-left: 19px;
      }
    }
  }
  & .info .label {
    font-family: var(--elliot-bold);
    color: #8e8e8e;
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 12px;
    margin-bottom: 7px;
  }

  & .info .value {
    font-family: var(--elliot-regular);
    line-height: 19px;
    font-size: 16px;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: calc(100%);
    overflow: hidden;
    color: var(--black);
    & svg {
      width: 25px;
      transform: scaleY(0.54);
    }
    & svg:first-child:nth-last-child(2) {
      margin-right: 9px;
    }
  }
}

.qual-footer-contributor {
  justify-content: space-between;
  align-items: center;

  & .footer-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 65%;
    line-height: 20px;
    opacity: 0.55;
    font-size: 14px;

    & .manager {
      color: #000;
      margin-left: 0.3rem;
      text-transform: uppercase;
      font-weight: 700;
    }

    & .text p {
      color: #000;
    }
    & .info .label {
      color: #000;
      letter-spacing: 1px;
    }

    & .info .value {
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: calc(100%);
      overflow: hidden;
      color: var(--black);

      & svg {
        width: 25px;
        transform: scaleY(0.54);
      }

      & svg:first-child:nth-last-child(2) {
        margin-right: 9px;
      }
    }
  }
  & .footer-info-read-only {
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 20px;
    opacity: 0.55;
    font-size: 14px;
    width: 35%;
    & .manager {
      color: #000;
      margin-left: 0.3rem;
      text-transform: uppercase;
      font-weight: 700;
    }

    & .text p {
      color: #000;
    }
  }
  & .footer-icons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 35%;

    .level {
      margin-bottom: 0;

      &-tag {
        display: flex;
        background-color: #3c4e65;
        border-radius: 24px;
        font-size: 12px;
        width: 195px;
        height: 22px;
        justify-content: center;
        align-items: center;
      }
    }

    p {
      line-height: 20px;
      opacity: 0.55;
      font-size: 14px;
      color: #000;
      text-transform: uppercase;
      margin-right: 39px;
    }

    & .icons {
      margin-right: 0;
      min-width: initial;
      display: flex;
      justify-content: space-between;
      align-items: center;

      img {
        padding-right: 15px;
      }

      & > * {
        pointer-events: initial;
        cursor: pointer;
        margin-left: 19px;
      }
    }
  }
  & .info {
    display: flex;
    min-width: 80px;
    max-width: 100%;

    &.flags {
      display: flex;
      flex-direction: column;
      align-items: center;

      .label {
        text-align: center;
        width: 100%;
      }

      .value {
        span {
          margin-right: 2px;
        }
      }

      margin-right: 0;
      min-width: fit-content;
      margin-left: auto;
    }
  }
}

@media (max-width: $breakpoint-middle-laptop) {
  .qual-footer-contributor {
    & .footer-info {
      //width: 65%;
      font-size: 12px;

      & .info .value {
        & svg {
          width: 15px;
          transform: scaleY(0.54);
        }

        & svg:first-child:nth-last-child(2) {
          margin-right: 9px;
        }
      }
    }

    & .footer-icons {
      .level {
        padding-left: 5px;
        height: auto;
      }
      p {
        font-size: 12px;
      }

      & .icons {
        img {
          padding-right: 5px;
          width: 20px;
          margin-left: 5px;
        }
      }
    }
  }
}

@media (max-width: $breakpoint-tablet) {
  .result-card .rating-icon {
    margin-right: 6px;
  }
}

.event-card {
  .qual-footer {
    font-size: 15px;
    grid-template-columns: 68% 1fr 30%;
  }
}

@media (max-width: 982px) {
  .offering-div {
    margin: 1rem 0;
    width: 100%;
  }
  .qual-div {
    margin: 1rem 0;
    width: 100%;
  }
  .offering-card {
    margin: 0 !important;
  }
  .qual-card {
    margin: 0 !important;
  }
}

@media (min-width: $breakpoint-tablet) {
  .qual-div {
    width: 360px;
  }
}
