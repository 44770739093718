@import "styles/_variables";

.event-entry {
  box-sizing: border-box;
  position: relative;
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & .event-date {
    font-family: var(--elliot-pro);
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 18px;
  }

  & .event-status {
    font-family: var(--elliot-bold);
  }

  & .event-status.green {
    color: var(--green);
  }

  & .event-status.yellow {
    color: var(--yellow);
  }

  & .event-name {
    font-family: var(--elliot-pro);
    line-height: 24px;
    font-size: 20px;
    margin-bottom: 8px;
  }

  & .event-manager {
    font-family: var(--elliot-pro);
    opacity: 0.49;
    font-size: 14px;
    line-height: 14px;
  }

  & .event-arrow {
    width: 14px;
    height: 14px;
    filter: var(--filter-red);
  }
}

/* Desktop Min Width 900 */

@media (min-width: $breakpoint-tablet) {
  .events {
    position: relative;

    & .title {
      box-sizing: border-box;
      margin: 0 auto;
      width: 760px;
      padding: 41px;
      padding-bottom: 13px;
      font-size: 16px;
      letter-spacing: 1.33px;
      background: #09192fcd;
      text-align: initial;
    }

    & .events-list {
      box-sizing: border-box;
      margin-left: auto;
      margin-right: auto;
      width: 760px;
      padding: 41px;
      background: #09192fcd;
      & .divider {
        margin: 22px 0;
      }
    }
  }

  .event-entry {
    & .event-date {
      font-size: 14px;
      letter-spacing: 1.17px;
      margin-bottom: 8px;
    }

    & .event-status {
      margin-left: 36px;
    }

    & .event-name {
      font-size: 24px;
      margin-bottom: 24px;
    }

    & .event-manager {
      font-size: 18px;
    }
  }
}
