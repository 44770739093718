@import "styles/_variables";
@import "styles/_mixin";

.title-container {
  box-sizing: border-box;
  width: calc(100% + 64px);
  margin-left: -32px;
  padding: 32px;
  background: #f7f8f9;
  padding-top: 6rem;

  &.blue-image {
    @include background-image-blue;

    .type,
    .title,
    .subtitle {
      color: white;
    }
  }
  &.gray-image {
    background: url("../../images/Quals background shape.svg") no-repeat left
      bottom;
  }

  .type {
    color: #353535;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 18px;
    text-transform: uppercase;
    padding-bottom: 0.5rem;
  }

  .title {
    font-size: 32px;
    letter-spacing: 0;
    line-height: 31px;
    margin-bottom: 24px;
    color: #353535;
    &.two-columns-variant {
      margin-bottom: 40px;
    }
  }
  .subtitle {
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
    color: #353535;
  }
  .columns {
    .subtitle {
      margin-bottom: 2em;
    }
  }
}

@media (min-width: $breakpoint-tablet) {
  .title-container {
    width: calc(100% + 128px);
    margin-left: -64px;
    padding: 72px 64px 56px;
    padding-top: 15rem;
    .title {
      font-size: 56px;
      line-height: 64px;
      color: #353535;
      &.two-columns-variant {
        margin-bottom: 64px;
        max-width: 50%;
      }
    }
    .subtitle {
      max-width: 55%;
      line-height: 28px;
      font-size: 18px;
    }
    .columns {
      display: flex;
      justify-content: space-between;
      > * {
        max-width: 45%;
        .subtitle {
          max-width: 100%;
          font-size: 20px;
          line-height: 32px;
        }
      }
    }
  }
}
