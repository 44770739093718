@import "styles/_variables";

@keyframes landingOptions {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes landingOptionsDesktop {
  from {
    opacity: 0.5;
  }
  to {
    transform: 0;
  }
}

.custom-select {
  &-wrapper {
    position: relative;
    margin-bottom: 0.5rem;
    padding: 0 0.3rem;

    @media (min-width: $breakpoint-tablet) {
      margin-bottom: 1.4rem;
    }
  }
  &-label-wrapper {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;
    padding-right: 0.5rem;

    > div:first-child {
      flex: 1;
    }
    & .autocomplete-input {
      flex-grow: 1;
      color: #666666;
      border: none;
      font-size: 18px;
      outline: none;
    }
  }
  &__input-container {
    @media (max-width: $breakpoint-tablet) {
      width: 100%;
    }

    .autocomplete-input {
      color: black;
      width: 100%;
    }
  }

  &-input {
    font-family: var(--elliot-regular);
    border-bottom: 1px solid #52697b;
    height: 48px;
    font-size: 18px;
    color: var(--black);
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    cursor: pointer;

    .label {
      color: var(--black);
    }

    .filter-number {
      background-color: #0c2340;
      border-radius: 50%;
      width: 1.3rem;
      height: 1.3rem;
      font-size: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &-arrow {
      @media (min-width: $breakpoint-tablet) {
        transform: rotate(90deg);
        margin-right: 0.3rem;
      }
    }
  }
  &-options {
    position: fixed;
    background-color: #fff;
    bottom: 0;
    left: 0;
    height: 90%;
    width: 100%;
    z-index: 400;
    overflow: auto;
    animation-name: landingOptions;
    animation-duration: 500ms;
    padding: 1rem;

    @media (min-width: $breakpoint-tablet) {
      animation-name: landingOptionsDesktop;
      position: relative;
      top: 0;
      left: 0;
      width: initial;
      z-index: initial;
      min-width: 100%;
      max-height: 385px;
      height: initial;
      overflow: auto;
      padding: 0.2rem;
      margin-bottom: 1rem;
      &.absolute {
        position: absolute;
        top: 48px;
        height: 30rem;
      }
    }

    .custom-select-input {
      justify-content: flex-start;
      align-items: center;
      padding: 2rem 0;
      cursor: pointer;
      &-arrow {
        transform: rotate(180deg);
        padding-left: 1rem;
      }
      @media (min-width: $breakpoint-tablet) {
        display: none;
      }

      .input-search-icon {
        display: inline-flex;
        z-index: 1000;
        position: absolute;
        width: 15px;
        margin-left: -20px;
        margin-top: 5px;
        vertical-align: baseline;
      }
    }
  }
  &-option {
    font-family: var(--elliot-regular);
    border-bottom: 1px solid #aeb4b9;
    font-size: 18px;
    color: var(--black);
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 1.2rem 0;
    cursor: pointer;
    @media (min-width: $breakpoint-tablet) {
      font-size: 15px;
      padding: 1rem 0;
    }
  }

  &-title {
    font-family: var(--elliot-regular);
    border-bottom: 1px solid #aeb4b9;
    font-size: 18px;
    text-transform: uppercase;
    background-color: #DFE6EF;
    color: var(--black);
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding: 1.2rem 0;
    @media (min-width: $breakpoint-tablet) {
      font-size: 15px;
      padding: 1rem 0;
    }
  }
}
