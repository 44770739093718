@import "styles/_variables";

.presentation-deck {
  position: relative;
  padding-top: 42px;
  margin-bottom: 42px;
  & .title {
    font-family: var(--elliot-bold);
    color: #000000;
    width: 100%;
    margin-bottom: 20px;
    letter-spacing: 1px;
    line-height: 18px;
    font-size: 16px;
  }
  & .image-container {
    width: 100%;
    display: flex;
    overflow-x: hidden;
    img {
      width: 100%;
      margin-right: 28px;
      max-height: 300px;
      height: 100%;
      &:last-child {
        margin: 0;
      }
    }
    > span {
      width: 100%;
      height: 100%;
    }
  }
  & .view {
    font-family: var(--elliot-bold);
    position: absolute;
    padding: 15px 17px;
    bottom: 14px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #ffffff90;
    border-radius: 20px;
    font-size: 12px;
    line-height: 10px;
    letter-spacing: 1px;
    color: #3c3c3c;
  }
  & .download {
    position: absolute;
    width: 41px;
    height: 41px;
    right: 67px;
    bottom: 13px;
    opacity: 0.84;
    img {
      width: 100%;
      height: 100%;
    }
  }
  & .download-bip {
    position: absolute;
    width: 41px;
    height: 41px;
    right: 17px;
    bottom: 13px;
    opacity: 1;
    img {
      width: 100%;
      height: 100%;
    }
  }

  & .tool-tip{
    background-color: lightgrey;
  }
  & .arrow-button {
    position: absolute;
    cursor: pointer;
    top: calc(50% + 35px);
    transform: translateY(-50%);
    height: 100%;
    width: 40px;
    padding: 10px;
    display: flex;
    align-items: center;

    &.left-scroll {
      left: -35px;
      transform: translateY(-50%) scaleX(-1);
    }
    &.right-scroll {
      right: -35px;
    }
    img {
      width: 100%;
    }
  }
}

@media (min-width: $breakpoint-tablet) {
  .presentation-deck {
    padding-top: 50px;
    margin-bottom: 106px;
    & .title {
      letter-spacing: 1.33px;
      line-height: 18px;
      font-size: 24px;
      margin-bottom: 40px;
    }
    & .view {
      display: none;
    }
    & .download {
      width: 48px;
      height: 48px;
      right: 100px;
      bottom: 32px;
    }
    & .download-bip {
      width: 48px;
      height: 48px;
      right: 45px;
      bottom: 32px;
    }
    & .image-container img {
      max-height: initial;
    }
  }
}
