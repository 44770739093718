.drop {

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #F7F8F9;
    border-radius: 4px;
    border: 1px solid #3C4E65;
  }

  &::-webkit-scrollbar-thumb {
    background: #3C4E65;
    border-radius: 4px;
  }

  &.scroll-down {
    height: auto;
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
    min-width: 100%;
    max-width: 100%;
    padding-right: 15px;
    margin: 0;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-bottom: 54px;
  }

}

.industry-header {
  background: #3C4E65;
  padding: 10px 24px;
  margin-bottom: 18px;
}

