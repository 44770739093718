@import "styles/_variables";

.filter-label {
  text-align: center;
  color: var(--black);
  font-family: var(--elliot-bold);
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 1px;
  line-height: 18px;
  padding-bottom: 1rem;
  margin-left: -1rem;
  margin-right: -1rem;
  border-bottom: 1px solid #e2e2e2;

  @media (min-width: $breakpoint-tablet) {
    text-align: left;
    letter-spacing: 1.33px;
    line-height: 18px;
    font-size: 16px;
    margin-bottom: 56px;
    margin-left: initial;
    margin-right: initial;
    border-bottom: initial;
    padding-bottom: initial;
  }

  .close-filters {
    position: absolute;
    right: 2rem;
    top: 3rem;
    cursor: pointer;
    img {
      width: 14px;
      height: 14px;
    }
    @media (min-width: $breakpoint-tablet) {
      display: none;
    }
  }
}

.filter-footer-button {
  background-color: #242242;
  width: 100%;
  height: 48px;
  font-size: 12px;
  font-family: var(--elliot-bold);
  letter-spacing: 1px;
  line-height: 10px;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-bottom: -2rem;

  @media (min-width: $breakpoint-tablet) {
    display: none;
  }
}

.filter-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1rem 0;
  overflow: auto;
  height: 100%;
  @media (min-width: $breakpoint-tablet) {
    overflow: initial;
  }
}

.filter-reset {
  display: flex;
  justify-content: flex-end;
  padding: 1rem 0;

  .link-reset {
    color: #000;
    font-size: 12px;
    font-family: var(--elliot-bold);
    letter-spacing: 1px;
    line-height: 10px;
    text-transform: uppercase;
    cursor: pointer;
  }
  @media (min-width: $breakpoint-tablet) {
    display: none;
  }
}

.range-label {
  color: #000;
  font-size: 16px;
  letter-spacing: 1px;
}

.range-error {
  color: #FF0000;
  font-size: 16px;
  letter-spacing: 1px;
}
