@import "styles/_variables";

.likes-comments-total-container {
  display: flex;
  flex-direction: row;
  margin: 10px 0;
}
.black-dot {
  color: #000000 !important;
  align-items: center;
  display: flex;
  margin: 0 9px;
  font-size: 20px;
  font-family: var(--elliot-bold);
  color: #000000;
  font-weight: bold;
  line-height: 20px;
  padding-top: 3px;
}

.likes-container {
  display: flex;
  align-items: center;

  .card-like-number {
    color: #000000;
    font-weight: bold;
    font-size: 12px;
    margin: 0 6px;
    line-height: 12px;
    padding-top: 3px;
  }

  .hide {
    display: none;
  }

  .like-reset-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    .big-like-image {
      height: 28px;
      margin-left: 28px;
    }
    .reset-button {
      background-color: transparent;
      border: 1px solid #0E4576;
      border-radius: 10px;
      height: 30px;
      margin: 10px 20px;
      width: 100px;

      span {
        padding: 0 5px;
        color: #0E4576;
      }
    }
  }
}

@media (min-width: $breakpoint_tablet) {
  .likes-container {
    .like-reset-container {

    }
  }
}
