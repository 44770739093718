@import "styles/_variables";

.video-container {
  width: 100%;

  iframe {
    width: 100%;
    margin: 0 auto;
    aspect-ratio: 16 / 9;
  }

  .player {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .info {
    display: flex;
    gap: 1rem;
    padding-top: 1rem;

    @media (min-width: $breakpoint-tablet) {
      padding-top: 1.5rem;
    }
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
}

.coming-soon {
  z-index: 1;
  position: absolute;
  color: rgb(255, 255, 255);
  font-size: 2em;
  font-weight: bold;
  transform-origin: bottom right;
  padding: 10px;
}
