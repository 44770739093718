@import "styles/_mixin";

.no-luck {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;

  @include background-image-blue;
  .wrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
  }
  svg {
    width: 600px;
    max-width: 100%;
  }

  h1 {
    font-family: var(--elliot-heavy);
  }
  .no-luck__button {
    padding: 12px 0;
    font-family: var(--elliot-bold);
    background: white;
    color: #0c2340;
    font-size: 12px;
    text-align: center;
    letter-spacing: 1px;
    border-radius: 4px;
    text-decoration: none;
    line-height: 18px;
    margin-bottom: 40px;
    width: 300px;
    max-width: 100%;
  }
  .regular {
    text-align: center;
  }
  .no-luck__mailto {
    font-family: var(--elliot-bold);
  }
}
