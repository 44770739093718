@import "styles/_variables";

.contact-card-container {
  border: 1px solid #ffffff;
  padding: 2.5rem;
  text-align: center;

  .heading_two {
    padding-bottom: 1rem;
  }
  .regular {
    padding-bottom: 2rem;
    width: 100% !important;
    text-align: left;
    ul {
        margin-left: 1rem;
      }
  }
  @media (min-width: $breakpoint-tablet) {
    position: relative;
    padding: 2rem 1.5rem;
    .heading_two {
      padding-bottom: 2rem;
    }
    .regular {
      padding-bottom: 4rem;

    }
    .link-underlined {
      position: absolute;
      bottom: 2.5rem;
      left: 0;
      right: 0;
    }
  }
}
