@import "styles/_variables";
.persona {
  &-header {
    display: grid;
    grid-template-columns: 5rem 1fr;
    align-items: center;
    padding-bottom: 1.5rem;
    @media (min-width: $breakpoint-tablet) {
      grid-template-columns: 8rem 1fr;
    }

    .logo-img {
      img {
        width: 55px;
        border-radius: 50%;
        height: 55px;
        @media (min-width: $breakpoint-tablet) {
          width: 92px;
          height: 92px;
        }
      }
    }
    .title-section {
      padding: 1.5rem 0.5rem;

      .title {
        color: #000000;
        font-size: 32px;
        letter-spacing: 0;
        line-height: 32px;
        text-transform: uppercase;
        @media (min-width: $breakpoint-tablet) {
          font-size: 56px;
          letter-spacing: 0;
          line-height: 64px;
        }
      }
      .short {
        display: none;
        @media (min-width: $breakpoint-tablet) {
          display: block;
          color: #0c2340;
          font-size: 14px;
          font-weight: bold;
          letter-spacing: 1px;
          line-height: 20px;
        }
      }
    }
  }
  &-wrapper {
    padding: 0.8rem 0;
    margin-bottom: 4rem;
    @media (min-width: $breakpoint-tablet) {
      display: flex;
      justify-content: space-between;

      > div:first-child {
        flex-basis: 40%;
      }
      > div:last-child {
        flex-basis: 40%;
        align-self: flex-end;
      }
    }
    .desc {
      color: #3c3c3c;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 24px;
      @media (min-width: $breakpoint-tablet) {
        color: #353535;
        font-size: 18px;
        letter-spacing: 0;
        line-height: 28px;
      }
    }
  }
}
