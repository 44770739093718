@import "styles/_variables";
@import "styles/_mixin";

.menu-container {
  @include background-image-gray;
  display: flex;
  flex-direction: column;

  @media (min-width: $breakpoint-tablet) {
    overflow: unset;
    flex-direction: row;
    justify-content: space-between;
    left: 0;
    background: transparent;
    position: relative;
    height: 4.5rem;
    padding: 0 2rem;
  }

  .menu-items {
    min-height: 80vh;

    @media (min-width: $breakpoint-tablet) {
      min-height: initial;
      display: flex;
      justify-content: space-around;
      align-items: center;
    }

    .menu-item {
      color: var(--white);
      font-size: calc(120%);
      padding: 1.2rem 2rem;
      font-family: var(--elliot-light);
      cursor: pointer;

      @media (min-width: $breakpoint-tablet) {
        padding: 1rem 0.5rem;
        color: #000000;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 27px;
      }

      @media (min-width: $breakpoint-laptop) {
        padding: 1rem 0.8rem;
        font-size: 16px;
      }

      @media (min-width: $breakpoint-large-laptop) {
        padding: 1rem 1.1rem;
        font-size: 18px;
      }

      span {
        color: var(--white);
        text-decoration: none;
      }
      &.active {
        font-family: var(--elliot-bold);
      }
    }

    .menu-spacer {
      height: 2.5rem;
      @media (min-width: $breakpoint-tablet) {
        height: 1.5rem;
        border-left: 1px solid var(--white);
        margin-left: 1.5rem;
        padding-left: 1.5rem;
      }
    }
  }

  .burger-container {
    display: grid;
    grid-template-columns: 15% 1fr;
    align-items: center;
    justify-items: start;
    padding: 2rem;
    @media (min-width: $breakpoint-tablet) {
      display: none;
    }
  }

  .menu-profile {
    @media (min-width: $breakpoint-tablet) {
      display: none;
    }
  }
}
