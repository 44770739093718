@import "styles/_variables";

.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 300;
  @media (min-width: $breakpoint-tablet) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.custom-modal {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 1rem;
  position: relative;
  background-color: #fff;

  @media (min-width: $breakpoint-tablet) {
    top: initial;
    left: initial;
    width: 50%;
    height: 80%;
    box-shadow: -1px 2px 7px -2px #4a4a4a;
    padding: 2rem;
  }

  &-header {
    display: flex;
    height: 50px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #eee;
    margin-left: -1rem;
    margin-right: -1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    svg {
      cursor: pointer;
    }
  }

  &-content {
    > div {
      margin-top: 1.3rem;
    }
    .title {
      color: #0d243f;
      font-size: 32px;
      letter-spacing: 0;
      line-height: 32px;
      @media (min-width: $breakpoint-tablet) {
        color: #353535;
        font-size: 47px;
        letter-spacing: 0;
        line-height: 64px;
      }
    }
    .date {
      color: #808080;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 18px;
      @media (min-width: $breakpoint-tablet) {
        font-size: 20px;
        letter-spacing: 0;
        line-height: 32px;
      }
    }
    .description {
      color: #353535;
      font-size: 16px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 24px;
      background-color: transparent;
      @media (min-width: $breakpoint-tablet) {
        font-size: 18px;
        letter-spacing: 0;
        line-height: 28px;
      }
    }
    .bu-label,
    .bu-link {
      color: #0c2340;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 22px;
      @media (min-width: $breakpoint-tablet) {
        font-size: 18px;
        letter-spacing: 0;
        line-height: 28px;
      }
    }
    .bu-link {
      text-decoration: underline;
    }
    .link-section {
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 8rem;
      background-color: #f7f8f9;
      padding: 1rem;
      &-title {
        color: #000000;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 1px;
        line-height: 18px;
        text-transform: uppercase;
        margin-bottom: 1.5rem;
        @media (min-width: $breakpoint-tablet) {
          font-size: 16px;
          font-weight: bold;
          letter-spacing: 1.33px;
          line-height: 18px;
        }
      }

      &-event {
        border-radius: 4px;
        background-color: #ffffff;
        padding: 0.3rem;
        height: 2rem;
        color: #0c2340;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 22px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        input {
          border: none;
          appearance: none;
          color: inherit;
          font-size: inherit;
          line-height: inherit;
          width: 100%;
        }

        svg {
          width: 2rem;
          height: 2rem;
          cursor: pointer;
        }

        @media (min-width: $breakpoint-tablet) {
          font-size: 16px;
          letter-spacing: 0;
          line-height: 22px;
          max-width: 70%;
        }
      }
      .copy-status {
        text-align: right;
        color: rgb(85, 145, 85);
        transition: all 300ms;
      }
    }
  }
}
