@import "styles/_variables";

.input-with-icon {
  &__container {
    display: flex;
    flex-direction: row;
    height: 50px;
    background-color: white;
    border: solid 0.5px #8c8e90;
    border-radius: 4px;
    padding-left: 16px;
    padding-right: 8px;
    font-size: 14px;
    color: #666666;

    @media (min-width: $breakpoint-tablet) {
      width: 364px;
      height: 42px;
    }
  }

  &__input {
    border: none;
    flex-grow: 1;
    background-color: transparent;
    z-index: 1;
    color: #666666;
    outline: none;
  }
  &__icon {
    width: 25px;
    background: transparent url("../../images/add_icon.svg") no-repeat 100%/contain;

    &:hover {
      cursor: pointer;
      background-image: url("../../images/add-circle-hover.svg");
    }
  }
}
