@import "styles/_variables";

.success-message-modal {
  height: 320px !important;
  width: 90% !important;
  max-width: 550px;
  margin: 0 auto auto auto;
  top: 50% !important;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);

  &:focus-visible {
    outline: none;
  }

  .close-icon-container {
    height: 24px;
    width: 24px;
    //position: absolute;
  }

  .success-modal-content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
    align-items: center;

    .description {
      margin: 10px 0 !important;
      font-weight: normal !important;
      color: #000000 !important;
      text-align: center;
    }

    .circle {
      background-color: #DFE6EF;
      border-radius: 50%;
      height: 130px;
      width: 130px;
    }
  }
}

@media (min-width: $breakpoint-tablet) {
  .success-message-modal {
    margin: auto !important;
    height: 270px !important;
    top: 0 !important;
    -ms-transform: translateY(0) !important;
    transform: translateY(0) !important;

    .circle {
      height: 104px !important;
      width: 104px !important;
    }
  }
}
