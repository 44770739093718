@import "styles/_variables";

.results-container .results {
  width: 100%;
}
.results-container .results-contributor {
  display: flex;
}

.results-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.results-list-contributor {
  width: calc(100% - 462px + 4rem);
  display: flex;
  flex-direction: column;
  align-items: stretch;

  &-header{
    display: flex;
    align-items: center;
    background-color: #3C4E65;
    height: 80px;
    font-size: 38px;
    font-weight: 400;
    letter-spacing: 1.17px;
    line-height: 32px;
    padding: 16px 0 18px 30px;
    width: 100%;
    color: #fff;
    margin-bottom: 40px;
  }
}



.results-container {
  display: flex;
  align-items: flex-start;
  margin-top: 2rem;

  .side-filter-bar {
    display: none;

    &.open-filter {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      position: fixed;
      z-index: 200;
      top: 0;
      right: 0;
      width: 100%;
      height: 100vh;
      background-color: #fff;
      padding: 3rem 1rem;
    }
  }

  .results {
    .manage-contributor {
      width: 100%;
      margin-bottom: 200px;

      .categories-container{
        width: 100%;
        margin-left: 0;
        .drop {
          &-header{
            display: flex;
            align-items: center;
            background-color: #3C4E65;
            height: 80px;
            font-size: 38px;
            font-weight: 400;
            letter-spacing: 1.17px;
            line-height: 32px;
            padding: 16px 0 18px 30px;
            width: 100%;
            color: #fff;
            margin-bottom: 40px;
            text-transform: uppercase;
          }

          & .level{
            height: 50px;
            font-size: 20px;
            border-radius: 0;
            & .icon-container{
              display: flex;
              position: absolute;
              right: 212px;
              width: 170px;
              justify-content: space-between;
            }
            & .level-marker {
              position: absolute;
              right: 130px;
              background-color: #F7F8F9;
            }
            & .filter-number {
              background-color: #0c2340;
              border-radius: 50%;
              width: 2rem;
              height: 2rem;
              font-size: 18px;
              display: flex;
              justify-content: center;
              align-items: center;
              color: #fff;
            }
            &-children {
              //border-left: 1px solid #0D2340;
              &-1{
                //border-left: 2px solid #0D2340;
                padding-left: 30px;
                margin-left: 30px;
                position: static;
                .level{
                  //margin-bottom: 0;

                }

                //&:not(:first-child)::before{
                //  content: "";
                //  position: absolute;
                //  left: -2px;
                //  top: -23px;
                //  height: 50px;
                //  border-left: 2px solid #0D2340;
                //}
              }
              &-2{
                //border-left: 2px solid #0D2340;
                padding-left: 60px;
                margin-left: 60px;
                position: static;
                .level{
                  //margin-bottom: 0;
                }
                //&:not(:first-child)::before{
                //  content: "";
                //  position: absolute;
                //  left: -2px;
                //  top: -23px;
                //  height: 50px;
                //  border-left: 2px solid #0D2340;
                //}
              }
              &-3{
                //border-left: 2px solid #0D2340;
                padding-left: 90px;
                margin-left: 90px;
                position: static;
                .level{
                  //margin-bottom: 0;
                }
                //&:not(:first-child)::before{
                //  content: "";
                //  position: absolute;
                //  left: -2px;
                //  top: -23px;
                //  height: 50px;
                //  border-left: 2px solid #0D2340;
                //}
              }

            }
          }
        }
      }
    }
  }
}

/* Desktop Min Width 900 */

@media (min-width: $breakpoint-tablet) {
  .filters-container .search-container {
    width: calc(33.333333% - 22.333333px);
    margin-left: auto;
  }

  .results-container {
    margin-top: 2rem;
    .side-filter-bar {
      flex-basis: 20%;
      margin-right: 3rem;
      display: flex;
      flex-direction: column;
      align-items: stretch;
    }
  }

  .results-list {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}
.test{
  display: none;
  background-color: red !important;
  border: 1px solid green;
}

.search-view-legend {
  align-items: flex-end;
  display: flex;
  flex-direction: column;

  .legend-item {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 230px;
    padding-bottom: 10px;

    .legend-text {
      color: var(--black);
      padding-left: 10px;
      font-family: var(--elliot-regular);
    }
  }
}
