@import "styles/_variables";

.serp {
  padding-top: 13.5rem !important;
  @media (min-width: $breakpoint-tablet) {
    padding-top: 15rem !important;
  }
}

.tabs-container {
  width: calc(100% + 8px);
  margin-left: -4px;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  height: 27px;
  overflow-x: scroll;

  -ms-overflow-style: none; /* HIDE SCROLLBAR */
  scrollbar-width: none; /* HIDE SCROLLBAR */

  &::-webkit-scrollbar {
    display: none; /* HIDE SCROLLBAR */
  }

  & .tab {
    font-family: var(--elliot-regular);
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 14px;
    color: #050505;
    margin-right: 20px;
    flex-shrink: 0;
    text-decoration: none;
    cursor: pointer;
  }
}

.tab.selected {
  font-family: var(--elliot-bold);
  border-bottom: 4px solid #0c233f;
}

.tab-content {
  display: none;
  &.visible-tab {
    display: block;
  }
}
/* Desktop Min Width 900 */

@media (min-width: $breakpoint-tablet) {
  /* TABS */
  .tabs-container {
    width: 100%;
    margin: 0;
    height: 28px;

    & .tab {
      font-size: 14px;
      letter-spacing: 1.17px;
      margin-right: 39px;
    }
  }
}
