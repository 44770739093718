@import "styles/_variables";

.admin {
  .contributor-header {
    margin-bottom: 0 !important;

    .title {
      grid-column: 1/3;
    }
  }

  .users-management-results {
    .results-label {
      display: none !important;
    }
  }

  .results-container .results {
    width: 100%;
  }

  .filters-container {
    margin-bottom: 0;
  }

  .filters-container .search-container {
    width: calc(33.333333% - 22.333333px);
    margin-left: auto;
  }

  .no-margin {
    margin: 0 !important;
  }

  .results-list {
    width: 100%;
    display: flex;
    align-items: stretch;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .results-container {
    display: flex;
    align-items: flex-start;
    margin-top: 3rem;

    .side-filter-bar {
      flex-basis: 20%;
      margin-right: 3rem;
      display: flex;
      flex-direction: column;
      align-items: stretch;

      &.open-filter {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        position: fixed;
        z-index: 200;
        top: 0;
        right: 0;
        width: 100%;
        height: 100vh;
        background-color: #fff;
        padding: 3rem 1rem;
      }
    }
  }
}
